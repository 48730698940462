import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import Widget from './Widget';
import MarketOverview from './MarketOverview';
import RecentActivity from './RecentActivity';
import Trading from './Trading';
import WalletBalance from './WalletBalance';
import MarketCap from './MarketCap';


const DashboardCrypto = () => {
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Crypto",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
             <MetaTags>
                <title>Dashboard Crypto | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-xxl-8">
                            <Widget />
                            <MarketOverview />
                            <Row>
                                <RecentActivity />
                                <Trading />
                            </Row>
                        </Col>
                        <WalletBalance />
                    </Row>
                    
                    <Row>
                        <MarketCap />
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default DashboardCrypto;