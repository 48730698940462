import React, { useEffect } from 'react';
import MetaTags from "react-meta-tags";


// redux
import { useDispatch } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import { setBreadcrumb } from "../../../store/actions";
import EmailSidebar from '../EmailSidebar';
import Inbox from './Inbox';

const EmailInbox = () => {
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Inbox",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    return (
        <React.Fragment>
            <MetaTags>
                <title>Inbox | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <EmailSidebar />
                            <Inbox />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmailInbox;