import React, { useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import ChartDonut from './ChartDonut';

const OrderState = () => {

    //Order Stats DropDown
    const [orderStats, setOrderStats] = useState(false);
    const toggleOrderStats = () => {
        setOrderStats(!orderStats);
    };
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-3">Order Stats</h5>
                        </div>
                        <div className="flex-shrink-0">
                            <Dropdown isOpen={orderStats} toggle={toggleOrderStats}>
                                <DropdownToggle className="text-muted" tag="a" type="button" to="#">
                                    <i data-eva="more-horizontal-outline" className="fill-muted"
                                        data-eva-height="18" data-eva-width="18"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem to="#">Yearly</DropdownItem>
                                    <DropdownItem to="#">Monthly</DropdownItem>
                                    <DropdownItem to="#">Weekly</DropdownItem>
                                    <DropdownItem to="#">Today</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <div id="chart-donut" className="mt-2">
                        <ChartDonut />
                    </div>

                    <div className="text-center mt-4 border-top">
                        <Row>
                            <Col xs="4">
                                <div className="pt-3">
                                    <p className="text-muted text-truncate mb-2">Completed</p>
                                    <h5 className="font-size-16 mb-0">70</h5>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div className="pt-3">
                                    <p className="text-muted text-truncate mb-2">Pending</p>
                                    <h5 className="font-size-16 mb-0">25</h5>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div className="pt-3">
                                    <p className="text-muted text-truncate mb-2">Cancel</p>
                                    <h5 className="font-size-16 mb-0">19</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default OrderState;