import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Rating
import Rating from "react-rating";

const UiRating = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Rating",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [customize, setcustomize] = useState("");
    const [reset, setreset] = useState("mdi mdi-star text-warning fa-2x");

    return (
        <React.Fragment>
            <MetaTags>
                <title>Rating | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Rater Js</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Rater Js Different Example For Rating</p>

                                    <Row>
                                        <Col sm={6}>
                                            <div className="p-lg-5 p-4 text-center" dir="ltr">
                                                <h5 className="font-size-15 mb-4">Basic Rater</h5>
                                                <div id="basic-rater">
                                                    <Rating
                                                        className="rating"
                                                        emptySymbol="mdi mdi-star-outline text-muted fa-2x"
                                                        fullSymbol="mdi mdi-star text-warning fa-2x"
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={6}>
                                            <div className="p-lg-5 p-4 text-center" dir="ltr">
                                                <h5 className="font-size-15 mb-4">Rater with Step</h5>
                                                <div id="rater-step">
                                                    <Rating
                                                        emptySymbol="mdi mdi-star-outline text-muted fa-2x"
                                                        fullSymbol="mdi mdi-star text-warning fa-2x"
                                                        fractions={2}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={6}>
                                            <div className="p-lg-5 p-4 text-center" dir="ltr">
                                                <h5 className="font-size-15 mb-4">Custom Messages</h5>
                                                <div id="rater-message">
                                                    <Rating
                                                        emptySymbol="mdi mdi-star-outline text-muted fa-2x"
                                                        fullSymbol="mdi mdi-star text-warning fa-2x"
                                                        fractions={2}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={6}>
                                            <div className="p-lg-5 p-4 text-center" dir="ltr">
                                                <h5 className="font-size-15 mb-4">Example with unlimited number of stars. readOnly option is set to true.</h5>
                                                <div id="rater-unlimitedstar">
                                                    <Rating
                                                        stop={16}
                                                        emptySymbol="mdi mdi-star-outline text-muted fa-1x"
                                                        fullSymbol="mdi mdi-star text-warning"
                                                        initialRating={4.5}
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={6}>
                                            <div className="p-lg-5 p-4 text-center" dir="ltr">
                                                <h5 className="font-size-15 mb-4">On Hover event</h5>
                                                <div id="rater-onhover" className="align-middle">
                                                    <Rating
                                                        stop={5}
                                                        emptySymbol="mdi mdi-star-outline text-muted fa-2x"
                                                        fullSymbol="mdi mdi-star text-warning fa-2x"
                                                        onChange={(customize) => setcustomize(customize)}
                                                    />
                                                </div>
                                                <span className="ratingnum badge bg-info align-middle ms-2">{customize}</span>
                                            </div>
                                        </Col>

                                        <Col sm={6}>
                                            <div className="p-lg-5 p-4 text-center" dir="ltr">
                                                <h5 className="font-size-15 mb-4">Clear/Reset rater</h5>
                                                <div id="raterreset" className="align-middle">
                                                    <Rating
                                                        emptySymbol="mdi mdi-star-outline text-muted fa-2x"
                                                        fullSymbol={reset}
                                                        onHover={() => setreset("mdi mdi-star text-warning fa-2x")}
                                                    />
                                                </div>
                                                <span className="clear-rating">
                                                </span>
                                                <button id="raterreset-button" className="btn btn-outline-primary btn-sm ms-2" onClick={() => setreset("mdi mdi-star-outline text-muted fa-2x")}>Reset</button>
                                            </div>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiRating;