import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownMenu,
    DropdownToggle,
    Modal,
    Row,
    UncontrolledDropdown,
} from "reactstrap";
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import { Link } from "react-router-dom";

const Customers = () => {
    const dispatch = useDispatch();

    /*
      set breadcrumbs
      */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Customers",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [modal_custo, setmodal_custo] = useState(false);
    const [modal_success, setmodal_success] = useState(false);

    function tog_custo() {
        setmodal_custo(!modal_custo);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Customers | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="position-relative">
                                        <div className="modal-button mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-success btn-rounded waves-effect waves-light mb-5 me-2"
                                                style={{ marginBottom: "50px" }}
                                                onClick={() => tog_custo()}
                                            >
                                                <i className="mdi mdi-plus me-1"></i> New Customer
                                            </button>
                                        </div>
                                    </div>

                                    <div id="table-ecommerce-customers" className="mt-3">
                                        <div
                                            role="complementary"
                                            className="gridjs gridjs-container"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="gridjs-head">
                                                <div className="gridjs-search">
                                                    <input
                                                        type="search"
                                                        placeholder="Type a keyword..."
                                                        aria-label="Type a keyword..."
                                                        className="gridjs-input gridjs-search-input"
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            fontSize: "13px",
                                                            borderRadius: "30px",
                                                            padding: "10px 13px",
                                                            width: "250px",
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className="gridjs-wrapper"
                                                style={{ height: "auto" }}
                                            >
                                                <table
                                                    role="grid"
                                                    className="gridjs-table"
                                                    style={{ height: "auto" }}
                                                >
                                                    <thead className="gridjs-thead">
                                                        <tr className="gridjs-tr">
                                                            <th
                                                                data-column-id="#"
                                                                className="gridjs-th"
                                                                style={{ minWidth: "48px", width: "71px" }}
                                                            >
                                                                <div className="gridjs-th-content">#</div>
                                                            </th>
                                                            <th
                                                                data-column-id="username"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "172px", width: "253px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Username
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="phone/Email"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "230px", width: "340px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Phone / Email
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="address"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "348px", width: "514px" }}
                                                            >
                                                                <div className="gridjs-th-content">Address</div>
                                                            </th>
                                                            <th
                                                                data-column-id="rating"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "80px", width: "118px" }}
                                                            >
                                                                <div className="gridjs-th-content">Rating</div>
                                                            </th>
                                                            <th
                                                                data-column-id="walletBalance"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "140px", width: "207px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Wallet Balance
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="joiningDate"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "122px", width: "180px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Joining Date
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="action"
                                                                className="gridjs-th"
                                                                style={{ minWidth: "68px", width: "101px" }}
                                                            >
                                                                <div className="gridjs-th-content">Action</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="gridjs-tbody">
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar1}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Stephen Rash
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">325-250-1106</p>
                                                                    <p className="mb-0">
                                                                        StephenRash@teleworm.us
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                2470 Grove Street Bethpage, NY 11714
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.2
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $5,412
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                07 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar2}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Juan Mays
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">443-523-4726</p>
                                                                    <p className="mb-0">JuanMays@armyspy.com</p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                3755 Harron Drive Salisbury, MD 21875
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.3
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $5,632
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                06 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar3}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Scott Henry
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">704-629-9535</p>
                                                                    <p className="mb-0">
                                                                        ScottHenry@jourrapide.com
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                3632 Snyder Avenue Bessemer City, NC 28016
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.1
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $7,523
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                06 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar4}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Cody Menendez
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">701-832-5838</p>
                                                                    <p className="mb-0">
                                                                        CodyMenendez@armyspy.com
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                4401 Findley Avenue Minot, ND 58701
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.0
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $6,325
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                05 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar5}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Jason Merino
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">706-219-4095</p>
                                                                    <p className="mb-0">JasonMerino@dayrep.com</p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                3159 Holly Street Cleveland, GA 30528
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.2
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $4,523
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                04 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar6}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Kyle Aquino
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">415-232-5443</p>
                                                                    <p className="mb-0">KyleAquino@teleworm.us</p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                4861 Delaware Avenue San Francisco, CA 94143
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.1
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $5,412
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                03 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="username"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar7}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        David Gaul
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="phone/Email"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <p className="mb-1">314-483-4679</p>
                                                                    <p className="mb-0">DavidGaul@teleworm.us</p>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="address"
                                                                className="gridjs-td"
                                                            >
                                                                1207 Cottrill Lane Stlouis, MO 63101
                                                            </td>
                                                            <td data-column-id="rating" className="gridjs-td">
                                                                <span>
                                                                    <span className="badge bg-success font-size-12">
                                                                        <i className="mdi mdi-star me-1"></i>4.4
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="walletBalance"
                                                                className="gridjs-td"
                                                            >
                                                                $5,412
                                                            </td>
                                                            <td
                                                                data-column-id="joiningDate"
                                                                className="gridjs-td"
                                                            >
                                                                02 Oct, 2021
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="card-drop"
                                                                            tag="a"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                                                                    Edit
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="dropdown-item">
                                                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                                                                    Delete
                                                                                </Link>
                                                                            </li>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="gridjs-footer mt-2">
                                                <div className="gridjs-pagination">
                                                    <div
                                                        role="status"
                                                        aria-live="polite"
                                                        className="gridjs-summary"
                                                        title="Page 1 of 3"
                                                    >
                                                        Showing <b>1</b> to <b>8</b> of <b>18</b> results
                                                    </div>

                                                    <div
                                                        className="gridjs-pages text-muted"
                                                        style={{ textAlign: "right" }}
                                                    >
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            disabled=""
                                                            title="Previous"
                                                            aria-label="Previous"
                                                            className=""
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            className="gridjs-currentPage"
                                                            title="Page 1"
                                                            aria-label="Page 1"
                                                        >
                                                            1
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            className=""
                                                            title="Page 2"
                                                            aria-label="Page 2"
                                                        >
                                                            2
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            className=""
                                                            title="Page 3"
                                                            aria-label="Page 3"
                                                        >
                                                            3
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            title="Next"
                                                            aria-label="Next"
                                                            className=""
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="gridjs-temp" className="gridjs-temp"></div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Customers Modal */}

            <Modal
                size="xl"
                isOpen={modal_custo}
                toggle={() => {
                    tog_custo();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="myExtraLargeModalLabel">
                        {" "}
                        New Customer{" "}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_custo(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="NewCustomers-Username">
                                    Username
                                </label>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Username"
                                    id="NewCustomers-Username"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="NewCustomers-Email">
                                    Email
                                </label>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    id="NewCustomers-Email"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="NewCustomers-Phone">
                                    Phone
                                </label>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone"
                                    id="NewCustomers-Phone"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="NewCustomers-Wallet">
                                    Wallet
                                </label>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="0"
                                    id="NewCustomers-Wallet"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label">Date</label>
                                <Flatpickr
                                    id="customers-date"
                                    className="form-control"
                                    placeholder="Select Date"
                                    options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d",
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="NewCustomers-Address">
                                    Address
                                </label>

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Address"
                                    id="NewCustomers-Address"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_custo(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    setmodal_success(true);
                                    setmodal_custo(false);
                                }}
                            >
                                <i className="bx bx-check me-1"></i> Confirm
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <h3 className="mt-3">New Customer Created Successfully</h3>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default Customers;
