import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import { Link } from 'react-router-dom';


const PricingPage = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Pricing",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Pricing | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="text-center mb-5">
                                                <h4>Choose your Pricing plan</h4>
                                                <p className="text-muted">To achieve this, it would be necessary to have uniform grammar, pronunciation and more common words If several languages coalesce</p>
                                            </div>
                                        </div>
                                    </div>

                                    <Row>
                                        <div className="col-xl-3 col-md-6">
                                            <div className="card plan-box overflow-hidden">
                                                <div className="bg-light p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5>Starter</h5>
                                                            <p className="text-muted mb-0">Neque quis est</p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-3">
                                                            <i className="bx bx-walk h1 mb-0 text-primary"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body p-4">
                                                    <div className="py-2 text-center">
                                                        <h1 className="mb-0"><sup><small>$</small></sup> 19/ <span className="font-size-14">Per month</span></h1>
                                                    </div>
                                                    <div className="plan-features mt-4">
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Free Live Support</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Unlimited User</p>
                                                        <p><i className="mdi mdi-close-circle text-danger me-2"></i> No Time Tracking</p>
                                                        <p><i className="mdi mdi-close-circle text-danger me-2"></i> Unlimited Dashboards</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Custom Css</p>
                                                    </div>
                                                    <div className="text-center plan-btn mt-4 pt-2">
                                                        <Link to="#" className="btn btn-primary waves-effect waves-light">Sign up Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="card plan-box overflow-hidden">
                                                <div className="bg-light p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5>Professional</h5>
                                                            <p className="text-muted mb-0">Quis autem iure</p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-3">
                                                            <i className="bx bx-run h1 mb-0 text-primary"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body p-4">
                                                    <div className="py-2 text-center">
                                                        <h1 className="mb-0"><sup><small>$</small></sup> 29/ <span className="font-size-14">Per month</span></h1>
                                                    </div>
                                                    <div className="plan-features mt-4">
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Free Live Support</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Unlimited User</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> No Time Tracking</p>
                                                        <p><i className="mdi mdi-close-circle text-danger me-2"></i> Unlimited Dashboards</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Custom Css</p>
                                                    </div>
                                                    <div className="text-center plan-btn mt-4 pt-2">
                                                        <Link to="#" className="btn btn-primary waves-effect waves-light">Sign up Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="card plan-box overflow-hidden">
                                                <div className="bg-primary p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5 className="text-white">Enterprise</h5>
                                                            <p className="text-white-50 mb-0">Sed ut neque unde</p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-3">
                                                            <i className="bx bx-cycling h1 mb-0 text-white"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body p-4">
                                                    <div className="py-2 text-center">
                                                        <h1 className="mb-0"><sup><small>$</small></sup> 39/ <span className="font-size-14">Per month</span></h1>
                                                    </div>
                                                    <div className="plan-features mt-4">
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Free Live Support</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Unlimited User</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> No Time Tracking</p>
                                                        <p><i className="mdi mdi-close-circle text-danger me-2"></i> Unlimited Dashboards</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Custom Css</p>
                                                    </div>
                                                    <div className="text-center plan-btn mt-4 pt-2">
                                                        <Link to="#" className="btn btn-primary waves-effect waves-light">Sign up Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6">
                                            <div className="card plan-box overflow-hidden">
                                                <div className="bg-light p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5>Unlimited</h5>
                                                            <p className="text-muted mb-0">Itaque earum hic</p>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-3">
                                                            <i className="bx bx-car h1 mb-0 text-primary"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body p-4">
                                                    <div className="py-2 text-center">
                                                        <h1 className="mb-0"><sup><small>$</small></sup> 49/ <span className="font-size-14">Per month</span></h1>
                                                    </div>
                                                    <div className="plan-features mt-4">
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Free Live Support</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Unlimited User</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> No Time Tracking</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Unlimited Dashboards</p>
                                                        <p><i className="mdi mdi-check-circle text-primary me-2"></i> Custom Css</p>
                                                    </div>
                                                    <div className="text-center plan-btn mt-4 pt-2">
                                                        <Link to="#" className="btn btn-primary waves-effect waves-light">Sign up Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PricingPage;