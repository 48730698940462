import React, { useEffect } from 'react';
import MetaTags from "react-meta-tags";

// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import AuthSlider from './authCarousel';

const TwoStepVerification = () => {
    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {

            document.body.className = "bg-transparent";
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });
    return (
        <React.Fragment>
            <MetaTags>
                <title>Two Step Verification | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">
                                                            <div className="mb-4 mb-md-5">
                                                                <Link to="/" className="d-block auth-logo">
                                                                    <img src={logodark} alt="" height="22" className="auth-logo-dark me-start" />
                                                                    <img src={logolight} alt="" height="22" className="auth-logo-light me-start" />
                                                                </Link>
                                                            </div>
                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <h4>Verify your email</h4>
                                                                    <p className="mb-5">Please enter the 4 digit code sent to <span className="fw-bold">example@abc.com</span></p>
                                                                </div>
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="digit1-input" className="visually-hidden">Dight 1</label>
                                                                                <input type="text"
                                                                                    className="form-control form-control-lg text-center two-step"
                                                                                    maxLength="1"
                                                                                    data-value="1"
                                                                                    id="digit1-input" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="digit2-input" className="visually-hidden">Dight 2</label>
                                                                                <input type="text"
                                                                                    className="form-control form-control-lg text-center two-step"
                                                                                    maxLength="1"
                                                                                    data-value="2"
                                                                                    id="digit2-input" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="digit3-input" className="visually-hidden">Dight 3</label>
                                                                                <input type="text"
                                                                                    className="form-control form-control-lg text-center two-step"
                                                                                    maxLength="1"
                                                                                    data-value="3"
                                                                                    id="digit3-input" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="digit4-input" className="visually-hidden">Dight 4</label>
                                                                                <input type="text"
                                                                                    className="form-control form-control-lg text-center two-step"
                                                                                    maxLength="1"
                                                                                    data-value="4"
                                                                                    id="digit4-input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-4">
                                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Confirm</button>
                                                                    </div>
                                                                </form>

                                                                <div className="mt-4 pt-3 text-center">
                                                                    <p className="text-muted mb-0">Didn't receive an email ? <Link to="#"
                                                                        className="text-primary fw-semibold"> Resend </Link> </p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 text-center">
                                                                <p className="mb-0">© {new Date().getFullYear()} Borex   . Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <AuthSlider />

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TwoStepVerification;