import React from 'react';
import ReactApexChart from "react-apexcharts";

const ChartArea = () => {
    const series = [{
        name: 'Current',
        data: [21, 54, 45, 84, 48, 56]
    }, {
        name: 'Previous',
        data: [40, 32, 60, 32, 55, 45]
    }];
    var options = {
        chart: {
            height: 270,
            type: 'area',
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        colors: ["#3b76e1", "#f56e6e"],
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
        },

        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100]
            },
        },
        yaxis: {
            tickAmount: 4,
        },
        xaxis: {
            categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },

    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height="270"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default ChartArea;