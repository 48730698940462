import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Ck Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Snow Editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const Editors = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Form Editors",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [value, setValue] = useState(`<h2>Hello World!</h2> 
    </br> 
    <h3>This is an simple editable area.</h3> 
    </br> 
    <ul>
        <li>
            Select a text to reveal the toolbar.
        </li>
        <li>
            Edit rich document on-the-fly, so elastic!
        </li>
    </ul>
    <p><br /></p>
    <p>
        End of simple area
    </p>`);

    const modules = {
        toolbar: [[{ 'font': [] }, { 'size': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'super' }, { 'script': 'sub' }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['direction', { 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']]
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Form Editors | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Ckeditor Classic Editor</h4>
                                </CardHeader>
                                <CardBody>
                                    <CKEditor editor={ClassicEditor} id="ckeditor-classic" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="mt-3 mb-3">
                                <h5 className="mb-0 pb-1 text-decoration-underline">Quilljs Editor</h5>
                            </div>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Snow Editor</h4>
                                </CardHeader>
                                <CardBody>

                                    <div id="snow-editor" style={{ height: "300px" }}>
                                        <ReactQuill theme="snow" id="snow-editor" value={value} onChange={setValue} modules={modules}
                                            style={{ height: "250px" }} />
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Bubble Editor</h4>
                                </CardHeader>
                                <CardBody>
                                    <div id="bubble-editor" style={{ height: "300px" }}>
                                        <ReactQuill theme="bubble" id="bubble-editor" value={value} onChange={setValue} style={{ height: "300px" }} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Editors;