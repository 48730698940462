import React, { useState, useEffect } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import { Link } from 'react-router-dom';


const UserGrid = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "UserGrid",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [modal_user, setmodal_user] = useState(false);
    const [modal_success, setmodal_success] = useState(false);

    function tog_user() {
        setmodal_user(!modal_user);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>User Grid | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="mb-3">
                                <h5 className="card-title">Contact List <span className="text-muted fw-normal ms-2">(834)</span></h5>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contacts-list" data-bs-toggle="tooltip" data-bs-placement="top" title="List"><i className="bx bx-list-ul"></i></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link active" to="/contacts-grid" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid"><i className="bx bx-grid-alt"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-primary" onClick={() => tog_user()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                </div>

                                <UncontrolledDropdown className="dropdown">
                                    <DropdownToggle tag="a" className="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle">
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">



                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>



                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={avatar2} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">Phyllis Gatlin</Link></h5>
                                            <span className="badge badge-soft-success mb-0">Full Stack Developer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            070 2860 5375</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            PhyllisGatlin@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            52 Ilchester MYBSTER 9WX</p>
                                    </div>

                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>


                                </div>
                            </Card>


                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">


                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>


                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={avatar1} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">James Nix</Link></h5>
                                            <span className="badge badge-soft-success mb-0">Full Stack Developer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            046 5685 6969</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            JamesNix@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            5 Boar Lane SELLING 2LG</p>
                                    </div>

                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">
                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={avatar3} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">Darlene Smith</Link></h5>
                                            <span className="badge badge-soft-danger mb-0">UI/UX Designer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            012 6587 1236</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            DarleneSmith@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            57 Guildry Street GAMRIE</p>
                                    </div>

                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">
                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="d-flex align-items-center">
                                        <div className="avatar-md">
                                            <div className="avatar-title bg-soft-primary text-primary display-6 m-0 rounded-circle">
                                                <i className="bx bxs-user-circle"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">William Swift</Link></h5>
                                            <span className="badge badge-soft-warning mb-0">Backend Developer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            012 6587 1236</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            WilliamSwift@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            80 South Street MONKW 7BR</p>
                                    </div>

                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>


                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">
                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="d-flex align-items-center">
                                        <div className="avatar-md">
                                            <div className="avatar-title bg-soft-primary text-primary display-6 m-0 rounded-circle">
                                                <i className="bx bxs-user-circle"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">Kevin West</Link></h5>
                                            <span className="badge badge-soft-success mb-0">Full Stack Developer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            052 6524 9896</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            KevinWest@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            88 Tadcaster PINCHBECK 6UB</p>
                                    </div>
                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">
                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={avatar6} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">Tommy Hayes</Link></h5>
                                            <span className="badge badge-soft-warning mb-0">Backend Developer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            065 2563 6587</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            TommyHayes@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            5 Boar Lane SELLING 2LG</p>
                                    </div>
                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">
                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={avatar8} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">Diana Owens</Link></h5>
                                            <span className="badge badge-soft-danger mb-0">UI/UX Designer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            087 6321 3235</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            DianaOwens@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            52 Ilchester MYBSTER 9WX</p>
                                    </div>
                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <div className="card-body">
                                    <UncontrolledDropdown className="dropdown float-end">
                                        <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Edit</DropdownItem>
                                            <DropdownItem>Action</DropdownItem>
                                            <DropdownItem>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={avatar4} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">Paul Sanchez</Link></h5>
                                            <span className="badge badge-soft-success mb-0">Full Stack Developer</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 pt-1">
                                        <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                            021 0125 5689</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                            DianaOwens@spy.com</p>
                                        <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                            80 South Street MONKW 7BR</p>
                                    </div>
                                    <div className="d-flex gap-2 pt-4">
                                        <button type="button" className="btn btn-soft-primary btn-sm w-50"><i className="bx bx-user me-1"></i> Profile</button>
                                        <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button>
                                    </div>
                                </div>
                            </Card>


                        </Col>

                    </Row>

                    <Row className="g-0 align-items-center pb-4">
                        <Col sm={6}>
                            <div>
                                <p className="mb-sm-0">Showing 1 to 10 of 57 entries</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="float-sm-end">
                                <ul className="pagination mb-sm-0">
                                    <li className="page-item disabled">
                                        <Link to="#" className="page-link"><i className="mdi mdi-chevron-left"></i></Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">2</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">5</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link"><i className="mdi mdi-chevron-right"></i></Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Add New Modal */}
            <Modal
                size="xl"
                isOpen={modal_user}
                toggle={() => {
                    tog_user();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="myExtraLargeModalLabel">
                        {" "}
                        Add New{" "}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_user(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Username">Username</label>
                                <input type="text" className="form-control" placeholder="Enter Username" id="AddNew-Username" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Position</label>
                                <select className="form-select">
                                    <option selected>Select Position</option>
                                    <option>Full Stack Developer</option>
                                    <option>Frontend Developer</option>
                                    <option>UI/UX Designer</option>
                                    <option>Backend Developer</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Email">Email</label>
                                <input type="text" className="form-control" placeholder="Enter Email" id="AddNew-Email" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Phone">Phone</label>
                                <input type="text" className="form-control" placeholder="Enter Phone" id="AddNew-Phone" />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_user(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    setmodal_success(true);
                                    setmodal_user(false);
                                }}
                            >
                                <i className="bx bx-check me-1"></i> Confirm
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <h3 className="mt-3">User Added Successfully</h3>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    );
};

export default UserGrid;