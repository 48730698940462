import React, { useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import OverviewChart from './OverviewChart';

const Overview = () => {
    //SortBy Dropdown
    const [sortby, setSortBy] = useState(false);
    const toggleSortby = () => {
        setSortBy(!sortby);
    };
    return (
        <React.Fragment>
            <Card >
                <CardBody className="pb-0">
                    <div className="d-flex align-itesms-start">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-3">Overview</h5>
                        </div>
                        <div className="flex-shrink-0">
                            <Dropdown isOpen={sortby} toggle={toggleSortby}>
                                <DropdownToggle tag="a" className="text-reset" type="button">
                                    <span className="fw-semibold">Sort By:</span> <span
                                        className="text-muted">Yearly<i
                                            className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>Yearly</DropdownItem>
                                    <DropdownItem>Monthly</DropdownItem>
                                    <DropdownItem>Weekly</DropdownItem>
                                    <DropdownItem>Today</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <Row className="gy-4">
                        <Col xxl={3}>
                            <div>
                                <div className="mt-3 mb-3">
                                    <p className="text-muted mb-1">This Month</p>

                                    <div className="d-flex flex-wrap align-items-center gap-2">
                                        <h2 className="mb-0">$24,568</h2>
                                        <div className="badge rounded-pill font-size-13 badge-soft-success">+
                                            2.65%</div>
                                    </div>
                                </div>

                                <Row className="g-0">
                                    <Col sm={6}>
                                        <div className="border-bottom border-end p-3 h-100">
                                            <p className="text-muted text-truncate mb-1">Orders</p>
                                            <h5 className="text-truncate mb-0">5,643</h5>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="border-bottom p-3 h-100">
                                            <p className="text-muted text-truncate mb-1">Sales</p>
                                            <h5 className="text-truncate mb-0">16,273</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col sm={6}>
                                        <div className="border-bottom border-end p-3 h-100">
                                            <p className="text-muted text-truncate mb-1">Order Value</p>
                                            <h5 className="text-truncate mb-0">12.03 %</h5>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="border-bottom p-3 h-100">
                                            <p className="text-muted text-truncate mb-1">Customers</p>
                                            <h5 className="text-truncate mb-0">21,456</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col sm={6}>
                                        <div className="border-end p-3 h-100">
                                            <p className="text-muted text-truncate mb-1">Income</p>
                                            <h5 className="text-truncate mb-0">$35,652</h5>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="p-3 h-100">
                                            <p className="text-muted text-truncate mb-1">Expenses</p>
                                            <h5 className="text-truncate mb-0">$12,248</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xxl={9}>
                            <div>
                                <div id="chart-column">
                                <OverviewChart />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Overview;
