import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

const Layout = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Form Layout",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    

    return (
        
        <React.Fragment>
            <MetaTags>
                <title>Form Layout | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Form Grid Layout</h4>
                                </CardHeader>
                                <CardBody>

                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="formrow-firstname-input" className="form-label">Enter First Name</label>
                                            <input type="text" className="form-control" placeholder="First Name" id="formrow-firstname-input" />
                                        </div>

                                        <Row>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-email-input" className="form-label">Enter Email</label>
                                                    <input type="email" className="form-control" placeholder="Email" id="formrow-email-input" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-password-input" className="form-label">Password</label>
                                                    <input type="password" className="form-control" placeholder="Enter Password" id="formrow-password-input" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputCity" className="form-label">City</label>
                                                    <input type="text" className="form-control" placeholder="Enter City" id="formrow-inputCity" />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputState" className="form-label">State</label>
                                                    <select id="formrow-inputState" className="form-select">
                                                        <option defaultValue>Choose...</option>
                                                        <option>...</option>
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputZip" className="form-label">Zip</label>
                                                    <input type="text" className="form-control" placeholder="Enter Zip" id="formrow-inputZip" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                <label className="form-check-label" htmlFor="gridCheck">
                                                    Check me out
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Horizontal Form Layout</h4>
                                </CardHeader>
                                <CardBody>

                                    <form>
                                        <Row className="mb-4">
                                            <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">First name</label>
                                            <Col sm={9}>
                                                <input type="text" className="form-control" placeholder="Enter First Name" id="horizontal-firstname-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <label htmlFor="horizontal-email-input" className="col-sm-3 col-form-label">Email</label>
                                            <Col sm={9}>
                                                <input type="email" className="form-control" placeholder="Enter Email" id="horizontal-email-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Password</label>
                                            <Col sm={9}>
                                                <input type="password" className="form-control" placeholder="Enter Password" id="horizontal-password-input" />
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-end">
                                            <Col sm={9}>

                                                <div className="form-check mb-4">
                                                    <input className="form-check-input" type="checkbox" id="horizontalLayout-Check" />
                                                    <label className="form-check-label" htmlFor="horizontalLayout-Check">
                                                        Remember me
                                                    </label>
                                                </div>

                                                <div>
                                                    <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Auto Sizing</h5>
                                </CardHeader>
                                <CardBody>

                                    <form className="row gy-2 gx-3 align-items-center">
                                        <div className="col-sm-auto">
                                            <label className="visually-hidden" htmlFor="autoSizingInput">Name</label>
                                            <input type="text" className="form-control" id="autoSizingInput" placeholder="Jane Doe" />
                                        </div>
                                        <div className="col-sm-auto">
                                            <label className="visually-hidden" htmlFor="autoSizingInputGroup">Username</label>
                                            <div className="input-group">
                                                <div className="input-group-text">@</div>
                                                <input type="text" className="form-control" id="autoSizingInputGroup" placeholder="Username" />
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                                            <select className="form-select" id="autoSizingSelect">
                                                <option defaultValue>Choose...</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="autoSizingCheck" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck">
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Inline forms</h5>
                                </CardHeader>
                                <CardBody>

                                    <form className="row row-cols-lg-auto g-3 align-items-center">
                                        <Col className="col-12">
                                            <label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</label>
                                            <div className="input-group">
                                                <div className="input-group-text">@</div>
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Username" />
                                            </div>
                                        </Col>

                                        <Col className="col-12">
                                            <label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</label>
                                            <select className="form-select" id="inlineFormSelectPref">
                                                <option defaultValue>Choose...</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </Col>

                                        <Col className="col-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="inlineFormCheck" />
                                                <label className="form-check-label" htmlFor="inlineFormCheck">
                                                    Remember me
                                                </label>
                                            </div>
                                        </Col>

                                        <Col className="col-12">
                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                        </Col>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title">Floating labels</h5>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p>

                                    <form>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingnameInput" placeholder="Enter Name" />
                                            <label htmlFor="floatingnameInput">Name</label>
                                        </div>
                                        <Row>
                                            <Col md={6}>
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" id="floatingemailInput" placeholder="Enter Email address" />
                                                    <label htmlFor="floatingemailInput">Email address</label>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="form-floating mb-3">
                                                    <select className="form-select" id="floatingSelectGrid" aria-label="Floating label select example">
                                                        <option defaultValue>Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                    <label htmlFor="floatingSelectGrid">Works with selects</label>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="floatingCheck" />
                                                <label className="form-check-label" htmlFor="floatingCheck">
                                                    Check me out
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Layout;