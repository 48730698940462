import React from 'react';
import ReactApexChart from "react-apexcharts";

const ChartDonut = () => {
    const series = [70, 25, 15];
    var options = {
        chart: {
            height: 220,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%',
                },
            }
        },
        dataLabels: {
            enabled: false,
        },
        labels: ["Completed", "Pending", "Cancel"],
        colors: ["#3b76e1", "#f1f3f7", "#f56e6e"],
        fill: {
            type: 'gradient',
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height="220"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default ChartDonut;