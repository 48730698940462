import React from 'react';
import ReactApexChart from "react-apexcharts";

const OverviewChart = () => {
    const series = [{
        name: 'Net Profit',
        data: [18, 21, 17, 24, 21, 27, 25, 32, 26]
    }, {
        name: 'Revenue',
        data: [21, 24, 20, 27, 25, 29, 26, 34, 30]
    }];
    var options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                borderRadius: 6,
                endingShape: 'rounded'
            },
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: ['#fff', '#fff'],
        xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value + "k";
                }
            },
            tickAmount: 4,
        },
        legend: {
            show: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#f1f3f7', '#3b76e1'],
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 38, 100, 38]
            },
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default OverviewChart;