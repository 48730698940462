import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import MetaTags from "react-meta-tags";

// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import AuthSlider from './authCarousel';

const Login = () => {
    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {

            document.body.className = "bg-transparent";
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">
                                                            <div className="mb-4 mb-md-5">
                                                                <Link to="/index" className="d-block auth-logo">
                                                                    <img src={logodark} alt="" height="22" className="auth-logo-dark me-start" />
                                                                    <img src={logolight} alt="" height="22" className="auth-logo-light me-start" />
                                                                </Link>
                                                            </div>

                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <h5 className="mb-0">Welcome Back !</h5>
                                                                    <p className="text-muted mt-2">Sign in to continue to Borex.</p>
                                                                </div>
                                                                <form className="mt-4 pt-2" action="/index">
                                                                    <div className="form-floating form-floating-custom mb-4">
                                                                        <input type="text" className="form-control" id="input-username" placeholder="Enter User Name" />
                                                                        <label htmlFor="input-username">Username</label>
                                                                        <div className="form-floating-icon">
                                                                            <Icon name="people-outline" fill="#555b6d" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                                        <input type="password" className="form-control pe-5" id="password-input" placeholder="Enter Password" />

                                                                        <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0" id="password-addon">
                                                                            <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                                        </button>
                                                                        <label htmlFor="password-input">Password</label>
                                                                        <div className="form-floating-icon">
                                                                            <Icon name="lock-outline" fill="#555b6d" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-4">
                                                                        <div className="col">
                                                                            <div className="form-check font-size-15">
                                                                                <input className="form-check-input" type="checkbox" id="remember-check" />
                                                                                <label className="form-check-label font-size-13" htmlFor="remember-check">
                                                                                    Remember me
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Log In</button>
                                                                    </div>
                                                                </form>

                                                                <div className="mt-4 pt-3 text-center">
                                                                    <div className="signin-other-title">
                                                                        <h5 className="font-size-14 mb-4 text-muted fw-medium">- Or you can join with -</h5>
                                                                    </div>

                                                                    <div className="d-flex gap-2">
                                                                        <button type="button" className="btn btn-soft-primary waves-effect waves-light w-100">
                                                                            <i className="bx bxl-facebook font-size-16 align-middle"></i>
                                                                        </button>
                                                                        <button type="button" className="btn btn-soft-info waves-effect waves-light w-100">
                                                                            <i className="bx bxl-linkedin font-size-16 align-middle"></i>
                                                                        </button>
                                                                        <button type="button" className="btn btn-soft-danger waves-effect waves-light w-100">
                                                                            <i className="bx bxl-google font-size-16 align-middle"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-4 pt-3 text-center">
                                                                    <p className="text-muted mb-0">Don't have an account ? <Link to="/auth-register"
                                                                        className="text-primary fw-semibold"> Signup now </Link> </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-4 text-center">
                                                                <p className="mb-0">© {new Date().getFullYear()} Borex   . Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;