import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const Widgets = () => {
    const widgets = [
        {
            id: 1,
            icon: "pie-chart-2",
            title: "Revenue",
            count: "$21,456",
            badgeClass: "soft-success",
            gainLoss: "+ 2.65%"
        },
        {
            id: 2,
            icon: "shopping-bag",
            title: "Orders",
            count: "5,643",
            badgeClass: "soft-danger",
            gainLoss: "- 0.82%"
        },
        {
            id: 3,
            icon: "people",
            title: "Customers",
            count: "45,254",
            badgeClass: "soft-danger",
            gainLoss: "- 1.04%"
        },
    ];
    return (
        <React.Fragment>
            <Row>
                {widgets.map((widgetsNew, key) => (
                    <Col xl={4} lg={6} key={key}>
                        <Card >
                            <CardBody className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-primary bg-gradient">
                                                <i data-eva={widgetsNew.icon} width="24px" height="24px" className="fill-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <p className="text-muted mb-1">{widgetsNew.title}</p>
                                        <h4 className="mb-0">{widgetsNew.count}</h4>
                                    </div>

                                    <div className="flex-shrink-0 align-self-end ms-2">
                                        <div className={"badge rounded-pill font-size-13 badge-" + widgetsNew.badgeClass}>{widgetsNew.gainLoss}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>

        </React.Fragment>
    );
};

export default Widgets;
