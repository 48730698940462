import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

//Simple bar
import SimpleBar from "simplebar-react";

const WalletBalance = () => {
    return (
        <React.Fragment>
            <Col className="col-xxl-4">
                <Card>
                    <CardBody>
                        <h5 className="card-title mb-4">Wallet Balance</h5>

                        <Card className="bg-primary bg-gradient text-white visa-card mb-0">
                            <CardBody>
                                <div>
                                    <i className="bx bxl-visa visa-pattern"></i>

                                    <div className="float-end">
                                        <i className="bx bxl-visa visa-logo display-3"></i>
                                    </div>

                                    <div>
                                        <i className="bx bx-chip h1 text-warning"></i>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-3">
                                        <p className="mb-2 text-truncate">
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <p className="mb-2 text-truncate">
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <p className="mb-2 text-truncate">
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <p className="mb-2 text-truncate">
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                            <i className="fas fa-star-of-life m-1"></i>
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <h5 className="text-white float-end mb-0">12/22</h5>
                                    <h5 className="text-white mb-0">Jennifer Bennett</h5>
                                </div>
                            </CardBody>
                        </Card>

                        <div className="mt-4">
                            <p className="text-muted">Available Balance</p>
                            <h4 className="font-size-20 mb-2">$46,256,342.00</h4>
                            <p className="text-muted">5,56.52645.258</p>

                            <div className="row mt-4 pb-3">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-soft-primary">
                                                <i className="mdi mdi-arrow-down font-size-22 text-primary"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-1 font-size-16">$84,652.00</h5>
                                            <p className="text-muted mb-0">Income</p>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-4 mb-md-0">
                                        <button type="button" className="btn btn-primary bg-gradient w-100">Receive</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <div className="avatar">
                                            <div className="avatar-title rounded bg-soft-danger">
                                                <i className="mdi mdi-arrow-up font-size-22 text-danger"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-1 font-size-16">$25,365.00</h5>
                                            <p className="text-muted mb-0">Expense</p>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <button type="button" className="btn btn-danger bg-gradient w-100">Send</button>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-4" />
                            <h4 className="card-title mb-4">Recent Transactions</h4>
                            <SimpleBar className="list-unstyled mb-0 px-3 mx-n3" style={{ maxHeight: "465px" }}>
                                <li className="py-2 pt-0">
                                    <div className="transactions-contain border rounded">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-5">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <div className="avatar-title rounded bg-soft-success">
                                                                <i className="bx bx-down-arrow-circle font-size-24 text-success"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1 text-truncate">Buy Bitcoin</h5>
                                                        <p className="text-muted mb-0">Today</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-sm-3">
                                                <div className="mt-3 mt-md-0 text-md-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">$ 1,020</h5>
                                                    <p className="text-muted mb-0 text-truncate">Amount</p>
                                                </div>
                                            </div>
                                            <div className="col-auto col-sm-4">
                                                <div className="mt-3 mt-md-0 text-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">0.016554 BTC</h5>
                                                    <p className="text-muted mb-0">Coin Value</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="py-2">
                                    <div className="transactions-contain border rounded">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-5">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <div className="avatar-title rounded bg-soft-success">
                                                                <i className="bx bx-down-arrow-circle font-size-24 text-success"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1 text-truncate">Buy Etherium</h5>
                                                        <p className="text-muted mb-0">Today</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-sm-3">
                                                <div className="mt-3 mt-md-0 text-md-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">$ 3,246</h5>
                                                    <p className="text-muted mb-0 text-truncate">Amount</p>
                                                </div>
                                            </div>
                                            <div className="col-auto col-sm-4">
                                                <div className="mt-3 mt-md-0 text-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">2.562444 ETH</h5>
                                                    <p className="text-muted mb-0">Coin Value</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="py-2">
                                    <div className="transactions-contain border rounded">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-5">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <div className="avatar-title rounded bg-soft-danger">
                                                                <i className="bx bx-up-arrow-circle font-size-24 text-danger"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1 text-truncate">Sell Litecoin</h5>
                                                        <p className="text-muted mb-0">Tomorrow</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-sm-3">
                                                <div className="mt-3 mt-md-0 text-md-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">$ 3,560</h5>
                                                    <p className="text-muted mb-0 text-truncate">Amount</p>
                                                </div>
                                            </div>
                                            <div className="col-auto col-sm-4">
                                                <div className="mt-3 mt-md-0 text-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">-0.526324 LTC</h5>
                                                    <p className="text-muted mb-0">Coin Value</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="py-2">
                                    <div className="transactions-contain border rounded">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-5">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <div className="avatar-title rounded bg-soft-success">
                                                                <i className="bx bx-down-arrow-circle font-size-24 text-success"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1 text-truncate">Buy Bitcoin</h5>
                                                        <p className="text-muted mb-0">Tomorrow</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-sm-3">
                                                <div className="mt-3 mt-md-0 text-md-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">$ 1,020</h5>
                                                    <p className="text-muted mb-0 text-truncate">Amount</p>
                                                </div>
                                            </div>
                                            <div className="col-auto col-sm-4">
                                                <div className="mt-3 mt-md-0 text-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">6.652654 BTC</h5>
                                                    <p className="text-muted mb-0">Coin Value</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="py-2">
                                    <div className="transactions-contain border rounded">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-5">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <div className="avatar-title rounded bg-soft-danger">
                                                                <i className="bx bx-up-arrow-circle font-size-24 text-danger"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="font-size-14 mb-1 text-truncate">Buy Bitcoin </h5>
                                                        <p className="text-muted mb-0">Yesterday</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-sm-3">
                                                <div className="mt-3 mt-md-0 text-md-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">$ 2,125</h5>
                                                    <p className="text-muted mb-0 text-truncate">Amount</p>
                                                </div>
                                            </div>
                                            <div className="col-auto col-sm-4">
                                                <div className="mt-3 mt-md-0 text-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">- 0.016554 BTC</h5>
                                                    <p className="text-muted mb-0">Coin Value</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="py-2">
                                    <div className="transactions-contain border rounded">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-5">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <div className="avatar-title rounded bg-soft-danger">
                                                                <i className="bx bx-up-arrow-circle font-size-24 text-danger"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1 text-truncate">Buy Bitcoin </h5>
                                                        <p className="text-muted mb-0">Yesterday</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-sm-3">
                                                <div className="mt-3 mt-md-0 text-md-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">$ 3,652</h5>
                                                    <p className="text-muted mb-0 text-truncate">Amount</p>
                                                </div>
                                            </div>
                                            <div className="col-auto col-sm-4">
                                                <div className="mt-3 mt-md-0 text-end">
                                                    <h5 className="font-size-14 mb-1 text-truncate">- 5.526552 BTC</h5>
                                                    <p className="text-muted mb-0 text-truncate">Coin Value</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </SimpleBar>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default WalletBalance;