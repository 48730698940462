import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Modal, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import Flatpickr from "react-flatpickr";
// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";

import Img1 from "../../assets/images/product/img-1.jpg";
import Img2 from "../../assets/images/product/img-2.jpg";
import { Link } from "react-router-dom";

const Orders = () => {
    const dispatch = useDispatch();

    /*
        set breadcrumbs
        */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Orders",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [modal_order, setmodal_order] = useState(false);
    const [modal_success, setmodal_success] = useState(false);

    function tog_order() {
        setmodal_order(!modal_order);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    const [modal_details, setmodal_details] = useState(false);

    function tog_details() {
        setmodal_details(!modal_details);
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Orders | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="position-relative">
                                        <div className="modal-button mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-success btn-rounded waves-effect waves-light mb-5 me-2"
                                                style={{ marginBottom: "50px" }}
                                                onClick={() => tog_order()}
                                            >
                                                <i className="mdi mdi-plus me-1"></i> Add New Order
                                            </button>
                                        </div>
                                    </div>

                                    <div id="table-ecommerce-orders" className="mt-3">
                                        <div
                                            role="complementary"
                                            className="gridjs gridjs-container"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="gridjs-head">
                                                <div className="gridjs-search">
                                                    <input
                                                        type="search"
                                                        placeholder="Type a keyword..."
                                                        aria-label="Type a keyword..."
                                                        className="gridjs-input gridjs-search-input"
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            fontSize: "13px",
                                                            borderRadius: "30px",
                                                            padding: "10px 13px",
                                                            width: "250px",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="gridjs-wrapper"
                                                style={{ height: "auto" }}
                                            >
                                                <table
                                                    role="grid"
                                                    className="gridjs-table"
                                                    style={{ height: "auto" }}
                                                >
                                                    <thead className="gridjs-thead">
                                                        <tr className="gridjs-tr">
                                                            <th
                                                                data-column-id="#"
                                                                className="gridjs-th"
                                                                style={{ minWidth: "48px", width: "87px" }}
                                                            >
                                                                <div className="gridjs-th-content">#</div>
                                                            </th>
                                                            <th
                                                                data-column-id="orderId"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "94px", width: "170px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Order ID
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="billingName"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "163px", width: "298px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Billing Name
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="date"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "110px", width: "199px" }}
                                                            >
                                                                <div className="gridjs-th-content">Date</div>
                                                            </th>
                                                            <th
                                                                data-column-id="total"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "71px", width: "129px" }}
                                                            >
                                                                <div className="gridjs-th-content">Total</div>
                                                            </th>
                                                            <th
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "148px", width: "266px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Payment Status
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "151px", width: "276px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    Payment Method
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="viewDetails"
                                                                className="gridjs-th gridjs-th-sort"
                                                                tabIndex="0"
                                                                style={{ minWidth: "122px", width: "221px" }}
                                                            >
                                                                <div className="gridjs-th-content">
                                                                    View Details
                                                                </div>
                                                            </th>
                                                            <th
                                                                data-column-id="action"
                                                                className="gridjs-th"
                                                                style={{ minWidth: "77px", width: "140px" }}
                                                            >
                                                                <div className="gridjs-th-content">Action</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="gridjs-tbody">
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK2540</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar1}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Neal Matthews
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                07 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $400
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">
                                                                        Paid
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fab fa-cc-mastercard me-2"></i>
                                                                    Mastercard
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK5623</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar2}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Jamal Burnett
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                05 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $452
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-danger font-size-12">
                                                                        Chargeback
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fab fa-cc-visa me-2"></i>Visa
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK6263</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar3}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Juan Mitchell
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                06 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $632
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-warning font-size-12">
                                                                        Refund
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fab fa-cc-paypal me-2"></i>
                                                                    Paypal
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK4521</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar4}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Barry Dick
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                07 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $521
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-warning font-size-12">
                                                                        Refund
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fas fa-money-bill-alt me-2"></i>
                                                                    COD
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK5263</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar5}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Ronald Taylor
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                07 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $521
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">
                                                                        Paid
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fab fa-cc-mastercard me-2"></i>
                                                                    Mastercard
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK4526</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar6}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Jacob Hunter
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                06 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $365
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-danger font-size-12">
                                                                        Chargeback
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fab fa-cc-visa me-2"></i>Visa
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK8965</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar7}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        William Cruz
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                07 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $452
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">
                                                                        Paid
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fab fa-cc-paypal me-2"></i>
                                                                    Paypal
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="gridjs-tr">
                                                            <td data-column-id="#" className="gridjs-td">
                                                                <span>
                                                                    <div className="form-check font-size-16">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="orderidcheck01"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="orderidcheck01"
                                                                        ></label>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="orderId"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="fw-semibold">#SK9658</span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="billingName"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={avatar8}
                                                                        alt=""
                                                                        className="avatar-sm rounded-circle me-2"
                                                                    />
                                                                    <Link to="#" className="text-body">
                                                                        Dustin Moser
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="date" className="gridjs-td">
                                                                08 Oct, 2021
                                                            </td>
                                                            <td data-column-id="total" className="gridjs-td">
                                                                $365
                                                            </td>
                                                            <td
                                                                data-column-id="paymentStatus"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <span className="badge badge-pill badge-soft-success font-size-12">
                                                                        Paid
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="paymentMethod"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <i className="fas fa-money-bill-alt me-2"></i>
                                                                    COD
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-column-id="viewDetails"
                                                                className="gridjs-td"
                                                            >
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btn-rounded"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".orderdetailsModal"
                                                                        onClick={() => tog_details()}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                </span>
                                                            </td>
                                                            <td data-column-id="action" className="gridjs-td">
                                                                <span>
                                                                    <div className="d-flex gap-3">
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-success"
                                                                            data-bs-original-title="Edit"
                                                                            aria-label="Edit"
                                                                        >
                                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title=""
                                                                            className="text-danger"
                                                                            data-bs-original-title="Delete"
                                                                            aria-label="Delete"
                                                                        >
                                                                            <i className="mdi mdi-delete font-size-18"></i>
                                                                        </Link>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="gridjs-footer mt-2">
                                                <div className="gridjs-pagination">
                                                    <div
                                                        role="status"
                                                        aria-live="polite"
                                                        className="gridjs-summary"
                                                        title="Page 1 of 3"
                                                    >
                                                        Showing <b>1</b> to <b>8</b> of <b>18</b> results
                                                    </div>
                                                    <div
                                                        className="gridjs-pages text-muted"
                                                        style={{ textAlign: "right" }}
                                                    >
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            disabled=""
                                                            title="Previous"
                                                            aria-label="Previous"
                                                            className=""
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            className="gridjs-currentPage"
                                                            title="Page 1"
                                                            aria-label="Page 1"
                                                        >
                                                            1
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            className=""
                                                            title="Page 2"
                                                            aria-label="Page 2"
                                                        >
                                                            2
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            className=""
                                                            title="Page 3"
                                                            aria-label="Page 3"
                                                        >
                                                            3
                                                        </button>
                                                        <button
                                                            tabIndex="0"
                                                           
                                                            title="Next"
                                                            aria-label="Next"
                                                            className=""
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="gridjs-temp" className="gridjs-temp"></div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* New Order Modal */}

            <Modal
                size="xl"
                isOpen={modal_order}
                toggle={() => {
                    tog_order();
                }}
                className="add-new-order"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="myExtraLargeModalLabel">
                        {" "}
                        Add New Order{" "}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_order(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddOrder-Product">
                                    Choose Product
                                </label>
                                <select className="form-select" id="AddOrder-Product">
                                    <option defaultValue> Select Product </option>
                                    <option>Adidas Running Shoes</option>
                                    <option>Puma P103 Shoes</option>
                                    <option>Adidas AB23 Shoes</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddOrder-Billing-Name">
                                    Billing Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Billing Name"
                                    id="AddOrder-Billing-Name"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label">Date</label>
                                <Flatpickr
                                    id="order-date"
                                    className="form-control"
                                    placeholder="Select Date"
                                    options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d",
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddOrder-Total">
                                    Total
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="$565"
                                    id="AddOrder-Total"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddOrder-Payment-Status">
                                    Payment Status
                                </label>
                                <select className="form-select" id="AddOrder-Payment-Status">
                                    <option defaultValue> Select Card Type </option>
                                    <option>Paid</option>
                                    <option>Chargeback</option>
                                    <option>Refund</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddOrder-Payment-Method">
                                    Payment Method
                                </label>
                                <select className="form-select" id="AddOrder-Payment-Method">
                                    <option defaultValue> Select Payment Method </option>
                                    <option> Mastercard</option>
                                    <option>Visa</option>
                                    <option>Paypal</option>
                                    <option>COD</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_order(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                onClick={() => {
                                    setmodal_success(true);
                                    setmodal_order(false);
                                }}
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                            >
                                <i className="bx bx-check me-1"></i> Confirm
                            </button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <h3 className="mt-3">Order Completed Successfully</h3>
                    </div>
                </div>
            </Modal>

            {/* View detail */}
            <Modal
                isOpen={modal_details}
                toggle={() => {
                    tog_details();
                }}
                className="orderdetailsModal"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="orderdetailsModalLabel">
                        {" "}
                        Order Details{" "}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_details(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <p className="mb-2">
                        Product id: <span className="text-primary">#SK2540</span>
                    </p>
                    <p className="mb-4">
                        Billing Name: <span className="text-primary">Neal Matthews</span>
                    </p>

                    <div className="table-responsive">
                        <table className="table align-middle table-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <div>
                                            <img src={Img1} alt="" className="rounded avatar-md" />
                                        </div>
                                    </th>
                                    <td>
                                        <div>
                                            <h5 className="text-truncate font-size-14">
                                                Waterproof Mobile Phone
                                            </h5>
                                            <p className="text-muted mb-0">$ 225 x 1</p>
                                        </div>
                                    </td>
                                    <td>$ 255</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <div>
                                            <img src={Img2} alt="" className="rounded avatar-md" />
                                        </div>
                                    </th>
                                    <td>
                                        <div>
                                            <h5 className="text-truncate font-size-14">
                                                Smartphone Dual Camera
                                            </h5>
                                            <p className="text-muted mb-0">$ 145 x 1</p>
                                        </div>
                                    </td>
                                    <td>$ 145</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <h6 className="m-0 text-right">Sub Total:</h6>
                                    </td>
                                    <td>$ 400</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <h6 className="m-0 text-right">Shipping:</h6>
                                    </td>
                                    <td>Free</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <h6 className="m-0 text-right">Total:</h6>
                                    </td>
                                    <td>$ 400</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                            setmodal_details(false);
                        }}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default Orders;
