import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import Img1 from "../../assets/images/product/img-1.jpg";
import Img2 from "../../assets/images/product/img-2.jpg";
import Img3 from "../../assets/images/product/img-3.jpg";
import Img5 from "../../assets/images/product/img-5.jpg";
import Img6 from "../../assets/images/product/img-6.jpg";

import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";

import classnames from "classnames";

import { Swiper, SwiperSlide } from "swiper/react";

//Simple bar
import SimpleBar from "simplebar-react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

import SwiperCore, {
    FreeMode, Navigation, Thumbs
} from 'swiper';
import { Link } from 'react-router-dom';

SwiperCore.use([FreeMode, Navigation, Thumbs]);

const ProductDetails = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Product Details",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [customActiveTab, setcustomActiveTab] = useState("2");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Product Details | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl={4}>
                                            <div className="product-detail mt-3" dir="ltr">

                                                <Swiper navigation={true} thumbs={{ swiper: thumbsSwiper }} className="swiper product-thumbnail-slider rounded border overflow-hidden position-relative">
                                                    <div className="swiper-wrapper">

                                                        <SwiperSlide><img src={Img1} alt="" className="img-fluid d-block" /></SwiperSlide>
                                                        <SwiperSlide><img src={Img2} alt="" className="img-fluid d-block" /></SwiperSlide>
                                                        <SwiperSlide><img src={Img3} alt="" className="img-fluid d-block" /></SwiperSlide>
                                                        <SwiperSlide><img src={Img6} alt="" className="img-fluid d-block" /></SwiperSlide>
                                                        <SwiperSlide><img src={Img5} alt="" className="img-fluid d-block" /></SwiperSlide>
                                                    </div>
                                                </Swiper>
                                                <div className="mt-4">
                                                    <Swiper onSwiper={setThumbsSwiper} slidesPerView={4} freeMode={true} watchSlidesProgress={true} className="swiper product-nav-slider mt-2 overflow-hidden">
                                                        <div className="swiper-wrapper">

                                                            <SwiperSlide className="rounded"><div className="nav-slide-item"><img src={Img1} alt="" className="img-fluid d-block rounded" /></div></SwiperSlide>
                                                            <SwiperSlide><div className="nav-slide-item"><img src={Img2} alt="" className="img-fluid d-block rounded" /></div></SwiperSlide>
                                                            <SwiperSlide><div className="nav-slide-item"><img src={Img3} alt="" className="img-fluid d-block rounded" /></div></SwiperSlide>
                                                            <SwiperSlide><div className="nav-slide-item"><img src={Img6} alt="" className="img-fluid d-block rounded" /></div></SwiperSlide>
                                                            <SwiperSlide><div className="nav-slide-item"><img src={Img5} alt="" className="img-fluid d-block rounded" /></div></SwiperSlide>
                                                        </div>
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={8}>
                                            <div className="mt-3 mt-xl-3 ps-xl-5">
                                                <h4 className="font-size-20 mb-3">Waterproof Mobile Phone +128GB</h4>

                                                <p className="text-muted mb-0">
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star text-warning"></i>
                                                    <i className="bx bxs-star-half text-warning"></i>
                                                </p>

                                                <div className="text-muted mt-2">
                                                    <span className="badge bg-success font-size-14 me-1"><i className="mdi mdi-star"></i> 4.5</span> 234 Reviews
                                                </div>

                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$500</del></span><b>$450</b></h3>

                                                <p className="mt-4 text-muted">If several languages coalesce, the grammar of the resulting language is more simple and regular</p>

                                                <div>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mt-3">
                                                                <h5 className="font-size-14">Specification :</h5>
                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 12MP TrueDepth front camera </p></li>
                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Compatible with MagSafe accessories</p></li>
                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Face ID for secure authentication</p></li>
                                                                </ul>
                                                            </div>
                                                        </Col>

                                                        <Col md={6}>
                                                            <div className="mt-3">
                                                                <h5 className="font-size-14">Services :</h5>
                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 10 Days Replacement </p></li>
                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Cash on Delivery available </p></li>
                                                                </ul>
                                                            </div>
                                                            <div className="mt-3">
                                                                <h5 className="font-size-14">Delivery location :</h5>

                                                                <div className="d-inline-flex mt-2">
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" placeholder="Enter Delivery pincode" />
                                                                        <button className="btn btn-primary" type="button">Check</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={6} sm={8}>
                                                            <div className="product-desc-color mt-3">
                                                                <h5 className="font-size-14">Colors :</h5>
                                                                <ul className="list-inline mt-3">
                                                                    <li className="list-inline-item">
                                                                        <i className="mdi mdi-circle font-size-18 text-dark"></i>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <i className="mdi mdi-circle font-size-18 text-success"></i>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <i className="mdi mdi-circle font-size-18 text-primary"></i>
                                                                    </li>

                                                                    <li className="list-inline-item">
                                                                        <Link to="#" className="text-primary border-0 p-1">
                                                                            2 + Colors
                                                                        </Link>
                                                                    </li>
                                                                </ul>

                                                            </div>


                                                            <Row className="text-center mt-4 pt-1">
                                                                <Col sm={6}>
                                                                    <div className="d-grid">
                                                                        <button type="button" className="btn btn-primary waves-effect waves-light mt-2 me-1">
                                                                            <i className="bx bx-cart me-2"></i> Add to cart
                                                                        </button>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="d-grid">
                                                                        <button type="button" className="btn btn-light waves-effect  mt-2 waves-light">
                                                                            <i className="bx bx-shopping-bag me-2"></i>Buy now
                                                                        </button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="col-8">
                                            <div className="mt-4 pt-3">
                                                <h5 className="font-size-14 mb-3">Reviews : </h5>
                                                <div className="text-muted mb-3">
                                                    <span className="badge bg-success font-size-14 me-1"><i className="mdi mdi-star"></i> 4.2</span> 234 Reviews
                                                </div>

                                                <div className="border py-4 rounded">

                                                    <SimpleBar className="px-4" data-simplebar style={{ maxHeight: "360px" }}>
                                                        <div className="border-bottom pb-3">
                                                            <p className="float-sm-end text-muted font-size-13">12 July, 2021</p>
                                                            <div className="badge bg-success mb-2"><i className="mdi mdi-star"></i> 4.1</div>
                                                            <p className="text-muted mb-4">Maecenas non vestibulum ante, nec efficitur orci. Duis eu ornare mi, quis bibendum quam. Etiam imperdiet aliquam purus sit amet rhoncus. Vestibulum pretium consectetur leo, in mattis ipsum sollicitudin eget. Pellentesque vel mi tortor.
                                                                Nullam vitae maximus dui dolor sit amet, consectetur adipiscing elit.</p>
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <img src={avatar2} className="avatar-sm rounded-circle" alt="" />
                                                                        <div className="flex-1 ms-2 ps-1">
                                                                            <h5 className="font-size-16 mb-0">Samuel</h5>
                                                                            <p className="text-muted mb-0 mt-1">65 Followers, 86 Reviews</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline product-review-link mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to="#"><i className="bx bx-like"></i></Link>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <Link to="#"><i className="bx bx-comment-dots"></i></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="border-bottom py-3">
                                                            <p className="float-sm-end text-muted font-size-13">06 July, 2021</p>
                                                            <div className="badge bg-success mb-2"><i className="mdi mdi-star"></i> 4.0</div>
                                                            <p className="text-muted mb-4">Cras ac condimentum velit. Quisque vitae elit auctor quam egestas congue. Duis eget lorem fringilla, ultrices justo consequat, gravida lorem. Maecenas orci enim, sodales id condimentum et, nisl arcu aliquam velit,
                                                                sit amet vehicula turpis metus cursus dolor cursus eget dui.</p>
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <img src={avatar3} className="avatar-sm rounded-circle" alt="" />
                                                                        <div className="flex-1 ms-2 ps-1">
                                                                            <h5 className="font-size-16 mb-0">Joseph</h5>
                                                                            <p className="text-muted mb-0 mt-1">85 Followers, 102 Reviews</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline product-review-link mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to="#"><i className="bx bx-like"></i></Link>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <Link to="#"><i className="bx bx-comment-dots"></i></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="pt-3">
                                                            <p className="float-sm-end text-muted font-size-13">26 June, 2021</p>
                                                            <div className="badge bg-success mb-2"><i className="mdi mdi-star"></i> 4.2</div>
                                                            <p className="text-muted mb-4">Aliquam sit amet eros eleifend, tristique ante sit amet, eleifend arcu. Cras ut diam quam. Fusce quis diam eu augue semper ullamcorper vitae sed massa. Mauris lacinia, massa a feugiat mattis, leo massa porta eros, sed congue arcu sem nec orci.
                                                                In ac consectetur augue. Nullam pulvinar risus non augue tincidunt blandit.</p>
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <img src={avatar6} className="avatar-sm rounded-circle" alt="" />
                                                                        <div className="flex-1 ms-2 ps-1">
                                                                            <h5 className="font-size-16 mb-0">Paul</h5>
                                                                            <p className="text-muted mb-0 mt-1">27 Followers, 66 Reviews</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline product-review-link mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to="#"><i className="bx bx-like"></i></Link>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <Link to="#"><i className="bx bx-comment-dots"></i></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </SimpleBar>

                                                    <div className="px-4 mt-2">
                                                        <div className="border rounded mt-4">
                                                            <form action="#">
                                                                <div className="px-2 py-1 bg-light">
                                                                    <div className="btn-group" role="group">
                                                                        <button type="button" className="btn btn-sm btn-link text-dark text-decoration-none"><i className="bx bx-link"></i></button>
                                                                        <button type="button" className="btn btn-sm btn-link text-dark text-decoration-none"><i className="bx bx-smile"></i></button>
                                                                        <button type="button" className="btn btn-sm btn-link text-dark text-decoration-none"><i className="bx bx-at"></i></button>
                                                                    </div>
                                                                </div>
                                                                <textarea rows="3" className="form-control border-0 resize-none" placeholder="Your Message..."></textarea>
                                                            </form>
                                                        </div>

                                                        <div className="text-end mt-3">
                                                            <button type="button" className="btn btn-success w-sm text-truncate ms-2"> Send <i className="bx bx-send ms-2 align-middle"></i></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="col-4">
                                            <div className="product-desc mt-4 pt-3">
                                                <h5 className="font-size-14 mb-3">Product description: </h5>

                                                <Nav tabs className="nav nav-tabs nav-tabs-custom">
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: customActiveTab === "1",
                                                            })}
                                                            onClick={() => {
                                                                toggleCustom("1");
                                                            }}
                                                            id="desc-tab"
                                                        >
                                                            Description
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: customActiveTab === "2",
                                                            })}
                                                            onClick={() => {
                                                                toggleCustom("2");
                                                            }}
                                                            id="specifi-tab"
                                                        >
                                                            Specifications
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>

                                                <TabContent
                                                    activeTab={customActiveTab}
                                                    className="border border-top-0 p-4"
                                                >
                                                    <TabPane tabId="1" id="desc">
                                                        <Row>
                                                            <Col md={3} sm={3}>
                                                                <div>
                                                                    <img src={Img3} alt="" className="img-fluid mx-auto rounded d-block" />
                                                                </div>
                                                            </Col>
                                                            <Col md={9} sm={9}>
                                                                <div className="text-muted p-2">
                                                                    <p>If several languages coalesce, the grammar of the resulting language is more simple.</p>
                                                                    <p>Everyone realizes a new common language would be desirable, one could refuse to pay expensive translators.</p>
                                                                    <p>It will be as simple as occidental in fact.</p>

                                                                    <div>
                                                                        <ul className="list-unstyled product-desc-list text-dark">
                                                                            <li><i className="mdi mdi-circle-medium me-1 align-middle text-primary"></i> Sed ut perspiciatis omnis iste</li>
                                                                            <li><i className="mdi mdi-circle-medium me-1 align-middle text-primary"></i> Neque porro quisquam est</li>
                                                                            <li><i className="mdi mdi-circle-medium me-1 align-middle text-primary"></i> Quis autem vel eum iure</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>

                                                    <TabPane tabId="2" id="specifi">
                                                        <div className="table-responsive">
                                                            <table className="table table-nowrap mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row" style={{ width: "50%" }}>Category :</th>
                                                                        <td>Phone</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Brand :</th>
                                                                        <td>Iphone</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Color :</th>
                                                                        <td>Dark</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Quality :</th>
                                                                        <td>High</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Material :</th>
                                                                        <td>Metal</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>

                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 pt-3">
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProductDetails;