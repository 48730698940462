import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

const RatingAndReviews = () => {

    //user RatingAndReviews
    const [userReview, setUserReview] = useState(false);
    const toogleUserReview = () => {
        setUserReview(!userReview);
    };

    //user RatingandReviews2
    const [userReview2, setUserReview2] = useState(false);
    const toogleUserReview2 = () => {
        setUserReview2(!userReview2);
    };

    //user RatingandReviews3
    const [userReview3, setUserReview3] = useState(false);
    const toogleUserReview3 = () => {
        setUserReview3(!userReview3);
    };

    //user RatingandReviews4
    const [userReview4, setUserReview4] = useState(false);
    const toogleUserReview4 = () => {
        setUserReview4(!userReview4);
    };

    return (
        <React.Fragment>
            <Col xl={6} >
                <Card >
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-3">Ratings & Reviews</h5>
                            </div>
                            <div className="flex-shrink-0">

                            </div>
                        </div>
                        <Row className="gy-4 gx-0">
                            <Col lg={6}>
                                <div>
                                    <div className="text-center">
                                        <h1>4.3</h1>
                                        <div className="font-size-16 mb-1">
                                            <i className="mdi mdi-star text-warning"></i>
                                            <i className="mdi mdi-star text-warning"></i>
                                            <i className="mdi mdi-star text-warning"></i>
                                            <i className="mdi mdi-star text-warning"></i>
                                            <i className="mdi mdi-star-half-full text-warning"></i>
                                        </div>
                                        <div className="text-muted">(14,454 Based)</div>
                                    </div>

                                    <div className="mt-4">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">5 <i
                                                        className="mdi mdi-star"></i></h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="p-1 py-2">
                                                    <div
                                                        className="progress animated-progess custom-progress">
                                                        <div className="progress-bar bg-gradient bg-primary"
                                                            role="progressbar" style={{ width: "90%" }}
                                                            aria-valuenow="90" aria-valuemin="0"
                                                            aria-valuemax="90">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-auto" >
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">50%</h5>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">4 <i
                                                        className="mdi mdi-star"></i></h5>
                                                </div>
                                            </Col>
                                            <Col >
                                                <div className="p-1 py-2">
                                                    <div
                                                        className="progress animated-progess custom-progress">
                                                        <div className="progress-bar bg-gradient bg-primary"
                                                            role="progressbar" style={{ width: "75%" }}
                                                            aria-valuenow="75" aria-valuemin="0"
                                                            aria-valuemax="75">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-auto">
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">20%</h5>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row className="align-items-center">
                                            <Col className="col-auto" >
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">3 <i
                                                        className="mdi mdi-star"></i></h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="p-1 py-2">
                                                    <div
                                                        className="progress animated-progess custom-progress">
                                                        <div className="progress-bar bg-gradient bg-primary"
                                                            role="progressbar" style={{ width: "60%" }}
                                                            aria-valuenow="60" aria-valuemin="0"
                                                            aria-valuemax="60">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-auto">
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">15%</h5>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">2 <i
                                                        className="mdi mdi-star"></i></h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="p-1 py-2">
                                                    <div
                                                        className="progress animated-progess custom-progress">
                                                        <div className="progress-bar bg-gradient bg-warning"
                                                            role="progressbar" style={{ width: "50%" }}
                                                            aria-valuenow="50" aria-valuemin="0"
                                                            aria-valuemax="50">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="col-auto" >
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">10%</h5>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row className="align-items-center">
                                            <Col className="col-auto" >
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">1 <i
                                                        className="mdi mdi-star"></i></h5>
                                                </div>
                                            </Col>
                                            <Col >
                                                <div className="p-1 py-2">
                                                    <div
                                                        className="progress animated-progess custom-progress">
                                                        <div className="progress-bar bg-gradient bg-danger"
                                                            role="progressbar" style={{ width: "20%" }}
                                                            aria-valuenow="20" aria-valuemin="0"
                                                            aria-valuemax="20">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-auto" >
                                                <div className="p-1 py-2">
                                                    <h5 className="font-size-16 mb-0">5%</h5>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>


                            <Col lg={6}>
                                <div className="ps-lg-4">
                                    <div className="d-flex flex-wrap align-items-start gap-3">
                                        <h5 className="font-size-15">Reviews: </h5>
                                        <p className="text-muted">(14,254 Based)</p>
                                    </div>

                                    <SimpleBar className="me-lg-n3 pe-lg-3" style={{ maxHeight: "266px" }}>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="badge bg-success bg-gradient mb-2"><i className="mdi mdi-star"></i> 4.1</div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <p className="text-muted font-size-13">12 Jul, 21</p>
                                                        </div>
                                                    </div>

                                                    <p className="text-muted mb-4">It will be as simple as in fact, It will seem like simplified</p>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <h5 className="font-size-14 mb-0">Samuel</h5>
                                                        </div>

                                                        <div className="flex-shrink-0">
                                                            <div className="hstack gap-3">
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Like">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-thumb-up-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Comment">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-comment-text-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <Dropdown isOpen={userReview} toggle={toogleUserReview}>
                                                                    <DropdownToggle className="text-muted" to="#" type="button" tag="a">
                                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                                    </DropdownToggle>

                                                                    <DropdownMenu className="dropdown-menu-right">
                                                                        <DropdownItem to="#">Action</DropdownItem>
                                                                        <DropdownItem to="#">Another action</DropdownItem>
                                                                        <DropdownItem to="#">Something else here</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="badge bg-success bg-gradient mb-2"><i className="mdi mdi-star"></i> 4.0</div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <p className="text-muted font-size-13">06 Jul, 21</p>
                                                        </div>
                                                    </div>
                                                    <p className="text-muted mb-4">Sed ut perspiciatis iste error sit</p>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <h5 className="font-size-14 mb-0">Joseph</h5>
                                                        </div>

                                                        <div className="flex-shrink-0">
                                                            <div className="hstack gap-3">
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Like">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-thumb-up-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Comment">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-comment-text-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <Dropdown isOpen={userReview4} toggle={toogleUserReview4} >
                                                                    <DropdownToggle className="text-muted" to="#" type="button" tag="a" >
                                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                                    </DropdownToggle>

                                                                    <DropdownMenu className="dropdown-menu-right">
                                                                        <DropdownItem to="#">Action</DropdownItem>
                                                                        <DropdownItem to="#">Another action</DropdownItem>
                                                                        <DropdownItem to="#">Something else here</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="badge bg-success bg-gradient mb-2"><i className="mdi mdi-star"></i> 4.2</div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <p className="text-muted font-size-13">26 Jun, 21</p>
                                                        </div>
                                                    </div>
                                                    <p className="text-muted mb-4">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet</p>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <h5 className="font-size-14 mb-0">Paul</h5>
                                                        </div>

                                                        <div className="flex-shrink-0">
                                                            <div className="hstack gap-3">
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Like">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-thumb-up-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Comment">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-comment-text-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <Dropdown isOpen={userReview2} toggle={toogleUserReview2}>
                                                                    <DropdownToggle className="text-muted" to="#" type="button" tag="a">
                                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                                    </DropdownToggle>

                                                                    <DropdownMenu className="dropdown-menu-right">
                                                                        <DropdownItem to="#">Action</DropdownItem>
                                                                        <DropdownItem to="#">Another action</DropdownItem>
                                                                        <DropdownItem to="#">Something else here</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="badge bg-success bg-gradient mb-2"><i className="mdi mdi-star"></i> 4.1</div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <p className="text-muted font-size-13">24 Jun, 21</p>
                                                        </div>
                                                    </div>
                                                    <p className="text-muted mb-4">Ut enim ad minima veniam, quis nostrum ullam corporis suscipit consequatur nisi ut</p>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <h5 className="font-size-14 mb-0">Patrick</h5>
                                                        </div>

                                                        <div className="flex-shrink-0">
                                                            <div className="hstack gap-3">
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Like">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-thumb-up-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Comment">
                                                                    <Link to="#" className="text-muted"><i className="mdi mdi-comment-text-outline"></i></Link>
                                                                </div>
                                                                <div className="vr"></div>
                                                                <Dropdown isOpen={userReview3} toggle={toogleUserReview3}>
                                                                    <DropdownToggle className="text-muted" to="#" type="button" tag="a">
                                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                                    </DropdownToggle>

                                                                    <DropdownMenu className="dropdown-menu-right">
                                                                        <DropdownItem to="#">Action</DropdownItem>
                                                                        <DropdownItem to="#">Another action</DropdownItem>
                                                                        <DropdownItem to="#">Something else here</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </SimpleBar>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default RatingAndReviews;
