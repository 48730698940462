import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

const TopProduct = () => {
    //top product list
    const topProductList = [
        {
            id: "#1",
            productName: "Polo blue T-shirt",
            price: "25.4",
            totalPrice: "3.82k"
        },
        {
            id: "#2",
            productName: "Hoodie for men",
            price: "24.5",
            totalPrice: "3.14k"
        },
        {
            id: "#3",
            productName: "Red color Cap",
            price: "22.5",
            totalPrice: "2.84k"
        },
        {
            id: "#4",
            productName: "Pocket T-shirt",
            price: "21.5",
            totalPrice: "2.06k"
        },
    ];
    //Top Product DropDown
    const [topProduct, setTopProduct] = useState(false);
    const toggleTopProduct = () => {
        setTopProduct(!topProduct);
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-3">Top Product</h5>
                        </div>
                        <div className="flex-shrink-0">
                            <Dropdown isOpen={topProduct} toggle={toggleTopProduct}>
                                <DropdownToggle className="text-muted" to="#" tag="a" type="button">
                                    Monthly<span><i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>Yearly</DropdownItem>
                                    <DropdownItem>Monthly</DropdownItem>
                                    <DropdownItem>Weekly</DropdownItem>
                                    <DropdownItem>Today</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <SimpleBar className="mx-n4" style={{ maxHeight: "296px" }}>
                        <ul className="list-unstyled mb-0">
                            {(topProductList || []).map((item, index) => (
                                <li className="px-4 py-3" key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-primary bg-gradient rounded">{item.id}</div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-muted mb-1 text-truncate">{item.productName}
                                            </p>
                                            <div className="fw-semibold font-size-15">$ {item.price}</div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <h5 className="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center">
                                                {item.totalPrice}</h5>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default TopProduct;