import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";
import DashboardSaas from "../pages/DashboardSaas/index";
import DashboardCrypto from "../pages/DashboardCrypto";

//Calendar
import Calendar from "../pages/Calendar/index";

//Chat
import Chat from "../pages/Chat/index";

//File Manager
import FileManager from "../pages/FileManager/index";

//Email
import EmailInbox from "../pages/Email/EmailInbox/index";
import EmailRead from "../pages/Email/ReadEmail/index";
import EmailBasicTemplte from "../pages/Email/email-basic-templte";
import EmailAlertTemplte from "../pages/Email/email-template-alert";
import EmailTemplateBilling from "../pages/Email/email-template-billing";

// Invoices
import InvoiceList from "../pages/Invoices/InvoiceList";
import InvoiceDeatil from "../pages/Invoices/InvoiceDetail";

// Projects
import ProjectGrid from "../pages/Projects/ProjectsGrid";
import ProjectList from "../pages/Projects/ProjectsList";
import CreateNew from "../pages/Projects/CreateNew";

// Ecommerce
import Products from "../pages/Ecommerce/Products";
import ProductDetails from "../pages/Ecommerce/ProductDetails";
import Orders from "../pages/Ecommerce/Orders";
import Customers from "../pages/Ecommerce/Customers";
import Cart from "../pages/Ecommerce/Cart";
import Checkout from "../pages/Ecommerce/Checkout";
import Shops from "../pages/Ecommerce/Shops";
import AddProduct from "../pages/Ecommerce/AddProduct";

// Contacts
import UserGrid from "../pages/Contacts/UserGrid";
import UserList from "../pages/Contacts/UserList";
import Profile from "../pages/Contacts/Profile";

// Auth
import AuthLogin from "../pages/Auth/login";
import Register from "../pages/Auth/Register";
import RecoverPassword from "../pages/Auth/RecoverPassword";
import LockScreen from "../pages/Auth/LockScreen";
import Logout from "../pages/Auth/Logout";
import ConfirmMail from "../pages/Auth/ConfirmMail";
import EmailVerification from "../pages/Auth/EmailVerification";
import TwoStepVerification from "../pages/Auth/TwoStepVerification";

// Utility
import StarterPage from "../pages/Utility/pages-starter";
import MaintenancePage from "../pages/Utility/pages-maintenance";
import ComingSoonPage from "../pages/Utility/pages-comingsoon";
import TimelinePage from "../pages/Utility/pages-timeline";
import FaqsPage from "../pages/Utility/pages-faqs";
import PricingPage from "../pages/Utility/pages-pricing";
import Error404 from "../pages/Utility/pages-404";
import Error500 from "../pages/Utility/pages-500";

// Ui
import UiAlerts from "../pages/Ui/UiAlerts";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdowns from "../pages/Ui/UiDropdowns";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import Uilightbox from "../pages/Ui/Uilightbox";
import UiModal from "../pages/Ui/UiModal";
import UiOffcanvas from "../pages/Ui/UiOffcanvas";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiProgressBars from "../pages/Ui/UiProgressBars";
import UiTabsAccordions from "../pages/Ui/UiTabs&Accordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiColors from "../pages/Ui/UiColors";
import UiRating from "../pages/Ui/UiRating";
import UiNotifications from "../pages/Ui/UiNotifications";

// Forms
import Elements from "../pages/Forms/Elements";
import Layout from "../pages/Forms/Layout";
import Validation from "../pages/Forms/Validation";
import Advanced from "../pages/Forms/Advanced";
import Editors from "../pages/Forms/Editors";
import FileUpload from "../pages/Forms/FileUpload";
import Wizard from "../pages/Forms/Wizard";
import Mask from "../pages/Forms/Mask";

// Tables
import BasicTables from "../pages/Tables/BasicTables";
import AdvanceTables from "../pages/Tables/AdvanceTables";

//Charts
import ApexCharts from "../pages/Charts/ApexCharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";

import EvaIcon from "../pages/Icons/EvaIcon";
import BoxiconsIcon from "../pages/Icons/BoxiconsIcon";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Authentication
import Login from "../pages/Authentication/login";

const authProtectedRoutes = [

    //dashboard
    { path: "/dashboard", component: Dashboard },
    { path: "/dashboard-saas", component: DashboardSaas },
    { path: "/dashboard-crypto", component: DashboardCrypto },

    //calendar
    { path: "/apps-calendar", component: Calendar },

    //Chats
    { path: "/apps-chat", component: Chat },

    //File manager
    { path: "/apps-file-manager", component: FileManager },

    // Email
    { path: "/email-inbox", component: EmailInbox },
    { path: "/email-read", component: EmailRead },
    { path: "/email-template-basic", component: EmailBasicTemplte },
    { path: "/email-template-alert", component: EmailAlertTemplte },
    { path: "/email-template-billing", component: EmailTemplateBilling },

    // Invoices
    { path: "/invoices-list", component: InvoiceList },
    { path: "/invoices-detail", component: InvoiceDeatil },

    // Projects
    { path: "/projects-grid", component: ProjectGrid },
    { path: "/projects-list", component: ProjectList },
    { path: "/projects-create", component: CreateNew },

    // Ecommerce
    { path: "/ecommerce-products", component: Products },
    { path: "/ecommerce-product-detail", component: ProductDetails },
    { path: "/ecommerce-orders", component: Orders },
    { path: "/ecommerce-customers", component: Customers },
    { path: "/ecommerce-cart", component: Cart },
    { path: "/ecommerce-checkout", component: Checkout },
    { path: "/ecommerce-shops", component: Shops },
    { path: "/ecommerce-add-product", component: AddProduct },

    // Contacts
    { path: "/contacts-grid", component: UserGrid },
    { path: "/contacts-list", component: UserList },
    { path: "/contacts-profile", component: Profile },

    // Utility
    { path: "/pages-starter", component: StarterPage },
    { path: "/pages-timeline", component: TimelinePage },
    { path: "/pages-faqs", component: FaqsPage },
    { path: "/pages-pricing", component: PricingPage },

    // Ui
    { path: "/ui-alerts", component: UiAlerts },
    { path: "/ui-buttons", component: UiButtons },
    { path: "/ui-cards", component: UiCards },
    { path: "/ui-carousel", component: UiCarousel },
    { path: "/ui-dropdowns", component: UiDropdowns },
    { path: "/ui-grid", component: UiGrid },
    { path: "/ui-images", component: UiImages },
    { path: "/ui-lightbox", component: Uilightbox },
    { path: "/ui-modals", component: UiModal },
    { path: "/ui-offcanvas", component: UiOffcanvas },
    { path: "/ui-rangeslider", component: UiRangeSlider },
    { path: "/ui-progressbars", component: UiProgressBars },
    { path: "/ui-tabs-accordions", component: UiTabsAccordions },
    { path: "/ui-typography", component: UiTypography },
    { path: "/ui-video", component: UiVideo },
    { path: "/ui-general", component: UiGeneral },
    { path: "/ui-colors", component: UiColors },
    { path: "/ui-rating", component: UiRating },
    { path: "/ui-notifications", component: UiNotifications },
    // Forms
    { path: "/form-elements", component: Elements },
    { path: "/form-layouts", component: Layout },
    { path: "/form-validation", component: Validation },
    { path: "/form-advanced", component: Advanced },
    { path: "/form-editors", component: Editors },
    { path: "/form-uploads", component: FileUpload },
    { path: "/form-wizard", component: Wizard },
    { path: "/form-mask", component: Mask },

    // Tables
    { path: "/tables-basic", component: BasicTables },
    { path: "/tables-advanced", component: AdvanceTables },

    // Charts
    { path: "/charts-line", component: ApexCharts },
    { path: "/charts-chartjs", component: ChartjsChart },
    { path: "/charts-echart", component: EChart },

    { path: "/icons-evaicons", component: EvaIcon },
    { path: "/icons-boxicons", component: BoxiconsIcon },
    { path: "/icons-materialdesign", component: IconMaterialdesign },
    { path: "/icons-fontawesome", component: IconFontawesome },

    // Maps
    { path: "/maps-google", component: MapsGoogle },
    { path: "/maps-vector", component: MapsVector },
    { path: "/maps-leaflet", component: MapsLeaflet },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
    //login
    { path: "/login", component: Login },

    // Utility
    { path: "/pages-maintenance", component: MaintenancePage },
    { path: "/pages-comingsoon", component: ComingSoonPage },
    { path: "/pages-404", component: Error404 },
    { path: "/pages-500", component: Error500 },

    // Auth
    { path: "/auth-login", component: AuthLogin },
    { path: "/auth-register", component: Register },
    { path: "/auth-recoverpw", component: RecoverPassword },
    { path: "/auth-lock-screen", component: LockScreen },
    { path: "/auth-logout", component: Logout },
    { path: "/auth-confirm-mail", component: ConfirmMail },
    { path: "/auth-email-verification", component: EmailVerification },
    { path: "/auth-two-step-verification", component: TwoStepVerification },
];

export { authProtectedRoutes, publicRoutes };