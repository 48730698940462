import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const FaqsPage = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "FAQs",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>FAQs | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Frequently Asked Questions?</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">01.</h5>
                                                    <h5 className="faq-title mt-3">What is Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">The point of using Lorem Ipsum is that it
                                                        has a more-or-less normal they distribution of letters content here.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">02.</h5>
                                                    <h5 className="faq-title mt-3">Why use Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">If several languages coalesce, the grammar resulting language is more simple and regular than individual languages.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">03.</h5>
                                                    <h5 className="faq-title mt-3">How many variations exist?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">The point of using Lorem Ipsum is that it has a more-or-less normal they distribution of letters content here.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">04.</h5>
                                                    <h5 className="faq-title mt-3">Is safe use Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">The point of using Lorem Ipsum is that it has a more-or-less normal they distribution of letters content here.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">05.</h5>
                                                    <h5 className="faq-title mt-3">Is safe use Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">The point of using Lorem Ipsum is that it has a more-or-less normal they distribution of letters content here.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">06.</h5>
                                                    <h5 className="faq-title mt-3">Is safe use Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">If several languages coalesce, the grammar resulting language is more simple and regular than individual languages.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">07.</h5>
                                                    <h5 className="faq-title mt-3">What is Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">If several languages coalesce, the grammar resulting language is more simple and regular than individual languages.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">08.</h5>
                                                    <h5 className="faq-title mt-3">How many variations exist?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">The point of using Lorem Ipsum is that it has a more-or-less normal they distribution of letters content here.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">09.</h5>
                                                    <h5 className="faq-title mt-3">Is safe use Lorem Ipsum?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">The point of using Lorem Ipsum is that it has a more-or-less normal they distribution of letters content here.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FaqsPage;