import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const Transaction = () => {
    
    //Transaction Dropdown
    const [transaction, setTransaction] = useState(false);
    const toggleTransaction = () => {
        setTransaction(!transaction);
    };

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-3">Transaction</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <Dropdown isOpen={transaction} toggle={toggleTransaction}>
                                    <DropdownToggle className="text-reset" to="#" type="button" tag="a">
                                        <span className="fw-semibold">Report By:</span> <span
                                            className="text-muted">Monthly<i
                                                className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem className="dropdown-item" href="#">Yearly</DropdownItem>
                                        <DropdownItem className="dropdown-item" href="#">Monthly</DropdownItem>
                                        <DropdownItem className="dropdown-item" href="#">Weekly</DropdownItem>
                                        <DropdownItem className="dropdown-item" href="#">Today</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table align-middle table-nowrap mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th className="align-middle">Order ID</th>
                                        <th className="align-middle">Billing Name</th>
                                        <th className="align-middle">Date</th>
                                        <th className="align-middle">Total</th>
                                        <th className="align-middle">Pay Status</th>
                                        <th className="align-middle">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Link to="#" className="text-body fw-semibold">#BR2150</Link> </td>
                                        <td>Smith</td>
                                        <td>07 Oct, 2021</td>
                                        <td>$24.05</td>
                                        <td className="text-center">
                                            <span className="badge badge-pill badge-soft-success font-size-11">Paid</span>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2">
                                                <button type="button" className="btn btn-primary bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                                    <i data-eva="eye" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-success bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                    <i data-eva="edit" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-danger bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                    <i data-eva="trash-2" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><Link to="#" className="text-body fw-semibold">#BR2149</Link> </td>
                                        <td>James</td>
                                        <td>07 Oct, 2021</td>
                                        <td>$26.15</td>
                                        <td className="text-center">
                                            <span className="badge badge-pill badge-soft-success font-size-11">Paid</span>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2">
                                                <button type="button" className="btn btn-primary bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                                    <i data-eva="eye" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-success bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                    <i data-eva="edit" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-danger bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                    <i data-eva="trash-2" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><Link to="#" className="text-body fw-semibold">#BR2148</Link> </td>
                                        <td>Jill</td>
                                        <td>06 Oct, 2021</td>
                                        <td>$21.25</td>
                                        <td className="text-center">
                                            <span className="badge badge-pill badge-soft-warning font-size-11">Refund</span>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2">
                                                <button type="button" className="btn btn-primary bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                                    <i data-eva="eye" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-success bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                    <i data-eva="edit" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-danger bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                    <i data-eva="trash-2" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><Link to="#" className="text-body fw-semibold">#BR2147</Link> </td>
                                        <td>Kyle</td>
                                        <td>05 Oct, 2021</td>
                                        <td>$25.03</td>
                                        <td className="text-center">
                                            <span className="badge badge-pill badge-soft-success font-size-11">Paid</span>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2">
                                                <button type="button" className="btn btn-primary bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                                    <i data-eva="eye" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-success bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                    <i data-eva="edit" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-danger bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                    <i data-eva="trash-2" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><Link to="#" className="text-body fw-semibold">#BR2146</Link> </td>
                                        <td>Robert</td>
                                        <td>05 Oct, 2021</td>
                                        <td>$22.61</td>
                                        <td className="text-center">
                                            <span className="badge badge-pill badge-soft-success font-size-11">Paid</span>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2">
                                                <button type="button" className="btn btn-primary bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                                    <i data-eva="eye" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-success bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                    <i data-eva="edit" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                                <button type="button" className="btn btn-danger bg-gradient btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                    <i data-eva="trash-2" data-eva-height="14" data-eva-width="14" className="fill-white align-text-top"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </CardBody>

                </Card>

            </Col>

        </React.Fragment>
    );
};

export default Transaction;
