import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";

import Img1 from "../../assets/images/companies/img-1.png";
import Img2 from "../../assets/images/companies/img-2.png";
import Img3 from "../../assets/images/companies/img-3.png";
import Img4 from "../../assets/images/companies/img-4.png";
import Img5 from "../../assets/images/companies/img-5.png";
import Img6 from "../../assets/images/companies/img-6.png";
import { Link } from 'react-router-dom';

const ProjectGrid = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Project Grid",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Project Grid | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img1} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">

                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>


                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">New admin Design</Link></h5>
                                        <p className="text-muted mb-0">It will be as simple as Occidental</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "87%" }} aria-valuenow="87" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 87%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 236
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 202
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                            A
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img2} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Brand logo design</Link></h5>
                                        <p className="text-muted mb-0">To achieve it would be necessary</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: "86%" }} aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 86%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 45
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 95
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar8} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img3} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">New Landing Design</Link></h5>
                                        <p className="text-muted mb-0">For science, music, sport, etc</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 75%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 124
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 325
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                                                            K
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img4} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Redesign - Landing page</Link></h5>
                                        <p className="text-muted mb-0">If several languages coalesce</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "92%" }} aria-valuenow="92" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 92%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 225
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 326
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar1} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar6} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar7} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img5} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Borex Dashboard UI</Link></h5>
                                        <p className="text-muted mb-0">Separate existence is a myth</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 75%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 321
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 214
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar1} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar3} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-danger text-white font-size-16">
                                                            3+
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img6} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Blog Template UI</Link></h5>
                                        <p className="text-muted mb-0">For science, music, sport, etc</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "88%" }} aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 88%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 125
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 143
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img4} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">App Landing UI</Link></h5>
                                        <p className="text-muted mb-0">To achieve it would be necessary</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: "84%" }} aria-valuenow="84" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 84%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 241
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 236
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-pink text-white font-size-16">
                                                            L
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img2} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">New admin Design</Link></h5>
                                        <p className="text-muted mb-0">Their most common words.</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "62%" }} aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 62%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 105
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 235
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                            A
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img2} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Brand logo design</Link></h5>
                                        <p className="text-muted mb-0">To achieve it would be necessary</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: "86%" }} aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 86%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 45
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 95
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar8} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img1} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">New admin Design</Link></h5>
                                        <p className="text-muted mb-0">It will be as simple as Occidental</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "97%" }} aria-valuenow="97" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 97%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 236
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 202
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                            A
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img3} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">New Landing Design</Link></h5>
                                        <p className="text-muted mb-0">For science, music, sport, etc</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 75%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 124
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 325
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                                                            K
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col xl={3} sm={6}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="avatar">
                                            <span className="avatar-title rounded bg-light text-danger font-size-16">
                                                <img src={Img6} alt="" height="30" />
                                            </span>
                                        </div>
                                        <div className="ms-auto">
                                            <UncontrolledDropdown className="dropdown float-end">
                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-18">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Remove</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 mt-4">
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Blog Template UI</Link></h5>
                                        <p className="text-muted mb-0">For science, music, sport, etc</p>

                                        <div className="mt-3 mb-1">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="progress" style={{ height: "6px" }}>
                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "88%" }} aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0 font-size-13"> 88%</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="px-4 py-2 border-top">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 125
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 143
                                            </li>
                                        </ul>

                                        <div className="avatar-group">
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                            <div className="avatar-group-item">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <div className="col-lg-12">
                            <ul className="pagination pagination-rounded justify-content-end mt-2 mb-5">
                                <li className="page-item disabled">
                                    <Link to="#" className="page-link"><i className="mdi mdi-chevron-left"></i></Link>
                                </li>
                                <li className="page-item active">
                                    <Link to="#" className="page-link">1</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">2</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">3</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">4</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">5</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link"><i className="mdi mdi-chevron-right"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectGrid;

