import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import patternBg from '../../assets/images/pattern-bg.jpg';
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import RadialBarCharts from './RadialBarCharts';

const UserSidebar = () => {
    //DropDown action
    const [profileDropdown, setProfileDropdown] = useState(false);
    const toggleprofileDropdown = () => {
        setProfileDropdown(!profileDropdown);
    };

    return (
        <React.Fragment>
            <div className="user-sidebar">
                <Card>
                    <CardBody className="p-0">
                        <div className="user-profile-img">
                            <img src={patternBg}
                                className="profile-img profile-foreground-img rounded-top"
                                style={{ height: "120px" }}
                                alt="" />
                            <div className="overlay-content rounded-top">
                                <div>
                                    <div className="user-nav p-3">
                                        <div className="d-flex justify-content-end">
                                            <Dropdown isOpen={profileDropdown} toggle={toggleprofileDropdown}>
                                                <DropdownToggle
                                                    className="dropdown-toggle"
                                                    to="#"
                                                    tag="a"
                                                    type="button">
                                                    <i data-eva="more-horizontal-outline" data-eva-width="20" data-eva-height="20"
                                                        className="fill-white"></i>
                                                </DropdownToggle>

                                                <DropdownMenu className="dropdown-menu-end">
                                                    <li><DropdownItem to="#">Action</DropdownItem></li>
                                                    <li><DropdownItem to="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem to="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-n5 position-relative">
                            <div className="text-center">
                                <img src={avatar1} alt=""
                                    className="avatar-xl rounded-circle img-thumbnail" />

                                <div className="mt-3">
                                    <h5 className="mb-1">Jennifer Bennett</h5>
                                    <p className="text-muted">Product Designer</p>
                                </div>
                            </div>
                        </div>

                        <div className="p-3">
                            <Row className="text-center pb-3">
                                <div className="col-6 border-end">
                                    <div className="p-1">
                                        <h5 className="mb-1">1,269</h5>
                                        <p className="text-muted mb-0">Products</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="p-1">
                                        <h5 className="mb-1">5.2k</h5>
                                        <p className="text-muted mb-0">Followers</p>
                                    </div>
                                </div>
                            </Row>

                            <hr className="mb-4" />


                            <div className="mb-4">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <h5 className="card-title mb-3">Earning</h5>
                                    </div>
                                    <div>
                                        <button className="btn btn-link py-0 shadow-none" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-trigger="hover" title="Info">
                                            <i data-eva="info-outline" className="fill-muted" data-eva-height="20" data-eva-width="20"></i>
                                        </button>
                                    </div>
                                </div>

                                <div id="chart-radialBar">
                                    <RadialBarCharts />
                                </div>

                                <div className="text-center mt-4">
                                    <h4>$26,256</h4>
                                    <p className="text-muted">Earning this Month</p>
                                    <div
                                        className="d-flex align-items-start justify-content-center gap-2">
                                        <div className="badge rounded-pill font-size-13 badge-soft-success">+ 2.65%
                                        </div>
                                        <div className="text-muted text-start text-truncate">From previous period</div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-4" />
                            <SimpleBar className="px-4 mx-n3" style={{ height: "258px" }}>
                                <div>
                                    <h5 className="card-title mb-3">Recent Activity</h5>

                                    <ul className="list-unstyled mb-0">
                                        <li className="py-2">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                                        <div className="text-center">
                                                            <h5 className="mb-0">12</h5>
                                                            <div>Sep</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 pt-2 text-muted">
                                                    <p className="mb-0">Responded to need “Volunteer Activities"</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="py-2">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                                        <div className="text-center">
                                                            <h5 className="mb-0">11</h5>
                                                            <div>Sep</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 pt-2 text-muted">
                                                    <p className="mb-0">Everyone realizes would be desirable... <Link to="#">Read more</Link></p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-md h-auto p-1 py-2 bg-light rounded">
                                                        <div className="text-center">
                                                            <h5 className="mb-0">10</h5>
                                                            <div>Sep</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 pt-2 text-muted">
                                                    <p className="mb-0">Joined the group “Boardsmanship Forum”</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="pt-2">
                                            <Link to="#" className="btn btn-link w-100 shadow-none"><i className="mdi mdi-loading mdi-spin me-2"></i> Load More</Link>
                                        </li>
                                    </ul>
                                </div>
                            </SimpleBar>
                        </div>
                    </CardBody>

                </Card>
            </div>
        </React.Fragment>
    );
};

export default UserSidebar;
