import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import Overview from './Overview';
import RatingAndReviews from './RatingAndReviews';
import UserActivity from './UserActivity';
import UserSidebar from './UserSidebar';
import Widgets from './Widgets';
import Transaction from './Transaction';

const DashBoard = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Dashboard",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
             <MetaTags>
                <title>Dashboard | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={9}>
                            <Widgets />
                            <Overview />
                            <UserActivity />
                        </Col>
                        <Col xxl={3}>
                            <UserSidebar />
                        </Col>
                    </Row>
                    <Row>
                        <RatingAndReviews />
                        <Transaction />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashBoard;
