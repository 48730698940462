import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";



const UiColors = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Colors",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Colors | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-primary p-4">
                                            <h5 className="my-2 text-white">#5c51e9</h5>
                                        </div>
                                        <div className="bg-primary bg-gradient p-4">
                                            <h5 className="my-2 text-white">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-primary p-4">
                                            <h5 className="my-2 text-primary">bg-soft</h5>
                                        </div>
                                    </div>

                                    <h5 className="mb-0 mt-3 text-primary">Primary</h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-success p-4">
                                            <h5 className="my-2 text-white">#82d173</h5>
                                        </div>
                                        <div className="bg-success bg-gradient p-4">
                                            <h5 className="my-2 text-white">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-success p-4">
                                            <h5 className="my-2 text-success">bg-soft</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-success">Success</h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-info p-4">
                                            <h5 className="my-2 text-white">#3c91e6</h5>
                                        </div>
                                        <div className="bg-info bg-gradient p-4">
                                            <h5 className="my-2 text-white">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-info p-4">
                                            <h5 className="my-2 text-info">bg-soft</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-info">Info</h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-warning p-4">
                                            <h5 className="my-2 text-white">#f7cc53</h5>
                                        </div>
                                        <div className="bg-warning bg-gradient p-4">
                                            <h5 className="my-2 text-white">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-warning p-4">
                                            <h5 className="my-2 text-warning">bg-soft</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-warning">Warning</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-danger p-4">
                                            <h5 className="my-2 text-white">#e54b4b</h5>
                                        </div>
                                        <div className="bg-danger bg-gradient p-4">
                                            <h5 className="my-2 text-white">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-danger p-4">
                                            <h5 className="my-2 text-danger">bg-soft</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-danger">Danger</h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-dark p-4">
                                            <h5 className="my-2 text-light">#141b2b</h5>
                                        </div>
                                        <div className="bg-dark bg-gradient p-4">
                                            <h5 className="my-2 text-light">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-dark p-4">
                                            <h5 className="my-2 text-dark">bg-soft</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-dark">Dark</h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">
                                        <div className="bg-secondary p-4">
                                            <h5 className="my-2 text-light">#7f838b</h5>
                                        </div>
                                        <div className="bg-secondary bg-gradient p-4">
                                            <h5 className="my-2 text-light">bg-gradient</h5>
                                        </div>
                                        <div className="bg-soft-secondary p-4">
                                            <h5 className="my-2 text-secondary">bg-soft</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-muted">Secondary</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={3} md={6}>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="rounded overflow-hidden">

                                        <div className="bg-primary opacity-75 p-4">
                                            <h5 className="my-2 text-white">opacity-75</h5>
                                        </div>
                                        <div className="bg-primary opacity-50 p-4">
                                            <h5 className="my-2 text-white">opacity-50</h5>
                                        </div>
                                        <div className="bg-soft-primary opacity-25 p-4">
                                            <h5 className="my-2 text-white">opacity-25</h5>
                                        </div>
                                    </div>
                                    <h5 className="mb-0 mt-3 text-muted">Opacity</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiColors;