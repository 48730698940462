import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const UiVideo = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Video",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Video | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Responsive embed video 16:9</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
                                    <div className="ratio ratio-16x9">
                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowFullScreen></iframe>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Responsive embed video 21:9</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
                                    <div className="ratio ratio-21x9">
                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowFullScreen></iframe>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Responsive embed video 4:3</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
                                    <div className="ratio ratio-4x3">
                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowFullScreen></iframe>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Responsive embed video 1:1</h4>
                                </CardHeader>
                                <CardBody>
                                    <p className="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
                                    <div className="ratio ratio-1x1">
                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowFullScreen></iframe>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiVideo;