import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

const SidebarContent = (props) => {
    const ref = useRef();

    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const activeMenu = useCallback(() => {
        const pathName = props.location.pathname;
        const fullPath = pathName;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (fullPath === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [props.location.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        activeMenu();
    }, [activeMenu]);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }
    return (
        <React.Fragment>
            <SimpleBar className="sidebar-menu-scroll" ref={ref}>
                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title" data-key="t-menu">Menu</li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="grid-outline"></i>
                                <span className="menu-item" data-key="t-dashboards">Dashboards</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/dashboard" data-key="t-ecommerce">Ecommerce</Link></li>
                                <li><Link to="/dashboard-saas" data-key="t-saas">Saas</Link></li>
                                <li><Link to="/dashboard-crypto" data-key="t-crypto">Crypto</Link></li>
                            </ul>
                        </li>

                        <li className="menu-title" data-key="t-applications">Applications</li>

                        <li>
                            <Link to="/apps-calendar">
                                <i className="icon nav-icon" data-eva="calendar-outline"></i>
                                <span className="menu-item" data-key="t-calendar">Calendar</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/apps-chat">
                                <i className="icon nav-icon" data-eva="message-circle-outline"></i>
                                <span className="menu-item" data-key="t-chat">Chat</span>
                                <span className="badge rounded-pill badge-soft-danger" data-key="t-hot">Hot</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/apps-file-manager">
                                <i className="icon nav-icon" data-eva="archive-outline"></i>
                                <span className="menu-item" data-key="t-filemanager">File Manager</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="shopping-bag-outline"></i>
                                <span className="menu-item" data-key="t-ecommerce">Ecommerce</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/ecommerce-products" data-key="t-products">Products</Link></li>
                                <li><Link to="/ecommerce-product-detail" data-key="t-product-detail">Product Detail</Link></li>
                                <li><Link to="/ecommerce-orders" data-key="t-orders">Orders</Link></li>
                                <li><Link to="/ecommerce-customers" data-key="t-customers">Customers</Link></li>
                                <li><Link to="/ecommerce-cart" data-key="t-cart">Cart</Link></li>
                                <li><Link to="/ecommerce-checkout" data-key="t-checkout">Checkout</Link></li>
                                <li><Link to="/ecommerce-shops" data-key="t-shops">Shops</Link></li>
                                <li><Link to="/ecommerce-add-product" data-key="t-add-product">Add Product</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="email-outline"></i>
                                <span className="menu-item" data-key="t-email">Email</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/email-inbox" data-key="t-inbox">Inbox</Link></li>
                                <li><Link to="/email-read" data-key="t-read-email">Read Email</Link></li>
                                <li>
                                    <Link to="/#">
                                        <span className="menu-item" data-key="t-email-templates">Templates</span>
                                        <span className="badge rounded-pill badge-soft-success" data-key="t-new">New</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/email-template-basic" data-key="t-basic-action">Basic Action</Link></li>
                                        <li><Link to="/email-template-alert" data-key="t-alert-email">Alert Email</Link></li>
                                        <li><Link to="/email-template-billing" data-key="t-bill-email">Billing Email</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="book-outline"></i>
                                <span className="menu-item" data-key="t-invoices">Invoices</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/invoices-list" data-key="t-invoice-list">Invoice List</Link></li>
                                <li><Link to="/invoices-detail" data-key="t-invoice-detail">Invoice Detail</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="briefcase-outline"></i>
                                <span className="menu-item" data-key="t-projects">Projects</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/projects-grid" data-key="t-p-grid">Projects Grid</Link></li>
                                <li><Link to="/projects-list" data-key="t-p-list">Projects List</Link></li>
                                <li><Link to="/projects-create" data-key="t-create-new">Create New</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="wifi-outline"></i>
                                <span className="menu-item" data-key="t-contacts">Contacts</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/contacts-grid" data-key="t-user-grid">User Grid</Link></li>
                                <li><Link to="/contacts-list" data-key="t-user-list">User List</Link></li>
                                <li><Link to="/contacts-profile" data-key="t-user-profile">Profile</Link></li>
                            </ul>
                        </li>

                        <li className="menu-title" data-key="t-layouts">Layouts</li>

                        <li className="menu-title" data-key="t-pages">Pages</li>

                        <li>
                            <Link to="/#">
                                <i className="icon nav-icon" data-eva="person-done-outline"></i>
                                <span className="menu-item" data-key="t-authentication">Authentication</span>
                                <span className="badge rounded-pill bg-info">8</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/auth-login" data-key="t-login">Login</Link></li>
                                <li><Link to="/auth-register" data-key="t-register">Register</Link></li>
                                <li><Link to="/auth-recoverpw" data-key="t-recover-password">Recover Password</Link></li>
                                <li><Link to="/auth-lock-screen" data-key="t-lock-screen">Lock Screen</Link></li>
                                <li><Link to="/auth-logout" data-key="t-logout">Logout</Link></li>
                                <li><Link to="/auth-confirm-mail" data-key="t-confirm-mail">Confirm Mail</Link></li>
                                <li><Link to="/auth-email-verification" data-key="t-email-verification">Email Verification</Link></li>
                                <li><Link to="/auth-two-step-verification" data-key="t-two-step-verification">Two Step Verification</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="cube-outline"></i>
                                <span className="menu-item" data-key="t-utility">Utility</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/pages-starter" data-key="t-starter-page">Starter Page</Link></li>
                                <li><Link to="/pages-maintenance" data-key="t-maintenance">Maintenance</Link></li>
                                <li><Link to="/pages-comingsoon" data-key="t-coming-soon">Coming Soon</Link></li>
                                <li><Link to="/pages-timeline" data-key="t-timeline">Timeline</Link></li>
                                <li><Link to="/pages-faqs" data-key="t-faqs">FAQs</Link></li>
                                <li><Link to="/pages-pricing" data-key="t-pricing">Pricing</Link></li>
                                <li><Link to="/pages-404" data-key="t-error-404">Error 404</Link></li>
                                <li><Link to="/pages-500" data-key="t-error-500">Error 500</Link></li>
                            </ul>
                        </li>

                        <li className="menu-title" data-key="t-components">Components</li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="layers-outline"></i>
                                <span className="menu-item" data-key="t-ui-elements">UI Elements</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/ui-alerts" data-key="t-alerts">Alerts</Link></li>
                                <li><Link to="/ui-buttons" data-key="t-buttons">Buttons</Link></li>
                                <li><Link to="/ui-cards" data-key="t-cards">Cards</Link></li>
                                <li><Link to="/ui-carousel" data-key="t-carousel">Carousel</Link></li>
                                <li><Link to="/ui-dropdowns" data-key="t-dropdowns">Dropdowns</Link></li>
                                <li><Link to="/ui-grid" data-key="t-grid">Grid</Link></li>
                                <li><Link to="/ui-images" data-key="t-images">Images</Link></li>
                                <li><Link to="/ui-lightbox" data-key="t-lightbox">Lightbox</Link></li>
                                <li><Link to="/ui-modals" data-key="t-modals">Modals</Link></li>
                                <li><Link to="/ui-offcanvas" data-key="t-offcanvas">Offcanvas</Link></li>
                                <li><Link to="/ui-rangeslider" data-key="t-range-slider">Range Slider</Link></li>
                                <li><Link to="/ui-progressbars" data-key="t-progress-bars">Progress Bars</Link></li>
                                <li><Link to="/ui-tabs-accordions" data-key="t-tabs-accordions">Tabs & Accordions</Link></li>
                                <li><Link to="/ui-typography" data-key="t-typography">Typography</Link></li>
                                <li><Link to="/ui-video" data-key="t-video">Video</Link></li>
                                <li><Link to="/ui-general" data-key="t-general">General</Link></li>
                                <li><Link to="/ui-colors" data-key="t-colors">Colors</Link></li>
                                <li><Link to="/ui-rating" data-key="t-rating">Rating</Link></li>
                                <li><Link to="/ui-notifications" data-key="t-notifications">Notifications</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="edit-2-outline"></i>
                                <span className="menu-item" data-key="t-forms">Forms</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/form-elements" data-key="t-form-elements">Form Elements</Link></li>
                                <li><Link to="/form-layouts" data-key="t-form-layouts">Form Layouts</Link></li>
                                <li><Link to="/form-validation" data-key="t-form-validation">Form Validation</Link></li>
                                <li><Link to="/form-advanced" data-key="t-form-advanced">Form Advanced</Link></li>
                                <li><Link to="/form-editors" data-key="t-form-editors">Form Editors</Link></li>
                                <li><Link to="/form-uploads" data-key="t-form-upload">Form File Upload</Link></li>
                                <li><Link to="/form-wizard" data-key="t-form-wizard">Form Wizard</Link></li>
                                <li><Link to="/form-mask" data-key="t-form-mask">Form Mask</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="list"></i>
                                <span className="menu-item" data-key="t-tables">Tables</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/tables-basic" data-key="t-basic-tables">Basic Tables</Link></li>
                                <li><Link to="/tables-advanced" data-key="t-advanced-tables">Advance Tables</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="pie-chart-outline"></i>
                                <span className="menu-item" data-key="t-charts">Charts</span>
                            </Link>
                            <ul className="sub-menu">
                            <li><Link to="/charts-line" data-key="t-e-charts">Apex Charts</Link></li>
                                <li><Link to="/charts-echart" data-key="t-e-charts">E Charts</Link></li>
                                <li><Link to="/charts-chartjs" data-key="t-chartjs-charts">Chartjs Charts</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="smiling-face-outline"></i>
                                <span className="menu-item" data-key="t-icons">Icons</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/icons-evaicons" data-key="t-evaicons">Eva Icons</Link></li>
                                <li><Link to="/icons-boxicons" data-key="t-boxicons">Boxicons</Link></li>
                                <li><Link to="/icons-materialdesign" data-key="t-material-design">Material Design</Link></li>
                                <li><Link to="/icons-fontawesome" data-key="t-font-awesome">Font Awesome 5</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="pin-outline"></i>
                                <span className="menu-item" data-key="t-maps">Maps</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/maps-google" data-key="t-google">Google</Link></li>
                                <li><Link to="/maps-vector" data-key="t-vector">Vector</Link></li>
                                <li><Link to="/maps-leaflet" data-key="t-leaflet">Leaflet</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="icon nav-icon" data-eva="share-outline"></i>
                                <span className="menu-item" data-key="t-multi-level">Multi Level</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="#" data-key="t-level-1.1">Level 1.1</Link></li>
                                <li><Link to="#" className="has-arrow" data-key="t-level-1.2">Level 1.2</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="#" data-key="t-level-2.1">Level 2.1</Link></li>
                                        <li><Link to="#" data-key="t-level-2.2">Level 2.2</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};
SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};
export default withRouter((SidebarContent));
