import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

//import Components
import Widgets from './Widgets';
import GlobalStatistics from './GlobalStatistics';
import AnalyticsOverview from './AnalyticsOverview';
import Statistics from './Statistics';
import Activities from './Activities';
import Inbox from './Inbox';
import Chat from './Chat';

const DashboardSaas = () => {
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Dashboard Saas",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    return (
        <React.Fragment>
             <MetaTags>
                <title>Dashboard Saas | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={5}>
                            <Widgets />
                        </Col>
                        <GlobalStatistics />
                    </Row>

                    <Row>
                        <AnalyticsOverview />
                        <Statistics />
                    </Row>

                    <Row>
                        <Activities />
                        <Inbox />
                        <Chat />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardSaas;