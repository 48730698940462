import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";
import moment from "moment";
import MetaTags from "react-meta-tags";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";


// redux
import { useDispatch } from "react-redux";
import { avatar1 } from "../../assets/images";
import {
    setBreadcrumb,
    getChats,
    getGroups,
    getMessages,
    getContacts,
    addMessage,
    // getContacts as onGetContacts,
    // getGroups as onGetGroups,
    // getMessages as onGetMessages,
} from "../../store/actions";

const Chat = (props) => {
    const { chats, groups, messages, contacts } = props;
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Chat",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [messageBox, setMessageBox] = useState(null);
    // const Chat_Box_Username2 = "Henry Wells"
    const [currentRoomId, setCurrentRoomId] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [currentUser, setCurrentUser] = useState({
        name: "Henry Wells",
        isActive: true,
    });
    const [menu1, setMenu1] = useState(false);
    const [search_Menu, setsearch_Menu] = useState(false);
    const [other_Menu, setother_Menu] = useState(false);
    const [activeTab, setactiveTab] = useState("1");
    const [Chat_Box_Username, setChat_Box_Username] = useState("Steven Franklin");
    // eslint-disable-next-line no-unused-vars
    const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online");
    const [curMessage, setcurMessage] = useState("");

    const { onGetChats, onGetGroups, onGetMessages, onGetContacts } = props;
    useEffect(() => {
        onGetChats();
        onGetGroups();
        onGetContacts();
        onGetMessages(currentRoomId);
    }, [onGetChats, onGetGroups, onGetMessages, onGetContacts, currentRoomId]);

    const scrollToBottom = useCallback(() => {
        if (messageBox) {
            messageBox.scrollTop = messageBox.scrollHeight + 1000;
        }
    }, [messageBox]);

    useEffect(() => {
        if (!isEmpty(messages)) scrollToBottom();
    }, [messages, scrollToBottom]);

    // const toggleNotification = () => {
    //   setnotification_Menu(!notification_Menu)
    // }

    //Toggle Chat Box Menus
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu);
    };



    const toggleOther = () => {
        setother_Menu(!other_Menu);
    };

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    //Use For Chat Box
    const userChatOpen = (id, name, status, roomId) => {
        const { onGetMessages } = props;
        setChat_Box_Username(name);
        setCurrentRoomId(roomId);
        onGetMessages(roomId);
    };

    const addMessage = (roomId, sender) => {
        const { onAddMessage } = props;
        const message = {
            id: Math.floor(Math.random() * 100),
            roomId,
            sender,
            message: curMessage,
            createdAt: new Date(),
        };
        setcurMessage("");
        onAddMessage(message);
    };

    const onKeyPress = e => {
        const { onAddMessage } = props;
        const { key, value } = e;
        if (key === "Enter") {
            setcurMessage(value);
            onAddMessage(currentRoomId, currentUser.name);
        }
    };

    //serach recent user
    const searchUsers = () => {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("search-user");
        filter = input.value.toUpperCase();
        ul = document.getElementById("recent-list");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Chat | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <div className="d-lg-flex">
                        <Card className="chat-leftsidebar">
                            <CardBody>
                                <div className="text-center bg-light rounded px-4 py-3">
                                    <div className="text-end">
                                        <Dropdown
                                            isOpen={menu1}
                                            toggle={() => setMenu1(!menu1)}
                                            className="chat-noti-dropdown"
                                        >
                                            <DropdownToggle tag="i" className="btn p-0">
                                                <i className="bx bx-cog"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem href="#">Profile</DropdownItem>
                                                <DropdownItem href="#">Edit</DropdownItem>
                                                <DropdownItem href="#">Add Contact</DropdownItem>
                                                <DropdownItem href="#">Setting</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className="chat-user-status">
                                        <img
                                            src={avatar1}
                                            className="avatar-md rounded-circle"
                                            alt=""
                                        />
                                        <div className="">
                                            <div className="status"></div>
                                        </div>
                                    </div>
                                    <h5 className="font-size-16 mb-1 mt-3"><Link to="#" className="text-dark">{currentUser.name} </Link></h5>
                                    <p className="text-muted mb-0">Available</p>

                                </div>
                            </CardBody>
                            <div className="p-3">
                                <div className="search-box position-relative">
                                    <Input
                                        onKeyUp={searchUsers}
                                        id="search-user"
                                        type="text"
                                        className="form-control rounded border"
                                        placeholder="Search..."
                                    />
                                    <i className="search-icon" data-eva="search-outline" data-eva-height="26" data-eva-width="26"></i>
                                </div>
                            </div>

                            <div className="chat-leftsidebar-nav">
                                <Nav pills justified className="bg-light p-1">
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleTab("1");
                                            }}
                                        >
                                            <i className="bx bx-chat font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Chat</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleTab("2");
                                            }}
                                        >
                                            <i className="bx bx-group font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Groups</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggleTab("3");
                                            }}
                                        >
                                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                                            <span className="d-none d-sm-block">Contacts</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <PerfectScrollbar className="chat-message-list">
                                            <div className="pt-3">
                                                <div className="px-3">
                                                    <h5 className="font-size-14 mb-3">Recent</h5>
                                                </div>
                                                <ul className="list-unstyled chat-list" id="recent-list">
                                                    {map(chats, chat => (
                                                        <li
                                                            key={chat.id + chat.status}
                                                            className={
                                                                currentRoomId === chat.roomId
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <Link
                                                                to="#"
                                                                onClick={() => {
                                                                    userChatOpen(
                                                                        chat.id,
                                                                        chat.name,
                                                                        chat.status,
                                                                        chat.roomId
                                                                    );
                                                                }}
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <div className={
                                                                        chat.status === "online"
                                                                            ? "flex-shrink-0 user-img online align-self-center me-3"
                                                                            : chat.status === "intermediate"
                                                                                ? "flex-shrink-0 user-img away align-self-center me-3"
                                                                                : "flex-shrink-0 user-img online align-self-center me-3"
                                                                    }>
                                                                        <span className="user-status"></span>
                                                                        <img
                                                                            src={chat.image}
                                                                            className="rounded-circle avatar-sm"
                                                                            alt=""
                                                                        />
                                                                    </div>

                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <h5 className="text-truncate font-size-14 mb-1">
                                                                            {chat.name}
                                                                        </h5>
                                                                        <p className="text-truncate mb-0">
                                                                            {chat.description}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex-shrink-0">
                                                                        {chat.time}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </PerfectScrollbar>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <PerfectScrollbar className="chat-message-list">
                                            <div className="pt-3">
                                                <div className="px-3">
                                                    <h5 className="font-size-14 mb-3">Groups</h5>
                                                </div>
                                                <ul className="list-unstyled chat-list">
                                                    {groups &&
                                                        groups.map(group => (
                                                            <li key={"test" + group.image}>
                                                                <Link
                                                                    to="#"
                                                                    onClick={() => {
                                                                        userChatOpen(
                                                                            group.id,
                                                                            group.name,
                                                                            group.status,
                                                                            Math.floor(Math.random() * 100)
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-sm me-3">
                                                                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                                {group.image}
                                                                            </span>
                                                                        </div>

                                                                        <div className="flex-grow-1">
                                                                            <h5 className="font-size-14 mb-0">
                                                                                {group.name}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </PerfectScrollbar>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <PerfectScrollbar className="chat-message-list">
                                            <div className="pt-3">
                                                <div className="px-3">
                                                    <h5 className="font-size-14 mb-3">Contacts</h5>
                                                </div>
                                                <div>
                                                    {contacts &&
                                                        contacts.map(contact => (
                                                            <div
                                                                key={"test_" + contact.category}
                                                                className={
                                                                    contact.category === "A" ? "" : "mt-4"
                                                                }
                                                            >
                                                                <div className="px-3 contact-list">{contact.category}</div>

                                                                <ul className="list-unstyled chat-list">
                                                                    {contact.child.map(array => (
                                                                        <li key={"test" + array.id}>
                                                                            <Link
                                                                                to="#"
                                                                                onClick={() => {
                                                                                    userChatOpen(
                                                                                        array.id,
                                                                                        array.name,
                                                                                        array.status,
                                                                                        Math.floor(Math.random() * 100)
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <h5 className="font-size-14 mb-0">
                                                                                    {array.name}
                                                                                </h5>
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Card>
                        <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-3">
                            <Card>
                                <div className="p-3 px-lg-4 border-bottom">
                                    <Row>
                                        <Col xl="4" xs="7">
                                            <h5 className="font-size-15 mb-1">
                                                {Chat_Box_Username}
                                            </h5>

                                            <p className="text-muted mb-0">
                                                <i
                                                    className={
                                                        Chat_Box_User_Status === "online"
                                                            ? "mdi mdi-circle text-success align-middle me-1"
                                                            : Chat_Box_User_Status === "intermediate"
                                                                ? "mdi mdi-circle text-warning align-middle me-1"
                                                                : "mdi mdi-circle align-middle me-1"
                                                    }
                                                />
                                                {Chat_Box_User_Status}
                                            </p>
                                        </Col>
                                        <Col md="8" xs="3">
                                            <ul className="list-inline user-chat-nav text-end mb-0">
                                                <li className="list-inline-item d-none d-sm-inline-block">
                                                    <Dropdown
                                                        isOpen={search_Menu}
                                                        toggle={toggleSearch}
                                                    >
                                                        <DropdownToggle className="btn nav-btn" tag="i">
                                                            <i className="bx bx-search-alt-2" />
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-md"
                                                        >
                                                            <Form className="p-3">
                                                                <FormGroup className="m-0">
                                                                    <InputGroup>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Search ..."
                                                                            aria-label="Recipient's username"
                                                                        />
                                                                        <Button color="primary" type="submit">
                                                                            <i className="mdi mdi-magnify" />
                                                                        </Button>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Form>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Dropdown
                                                        isOpen={other_Menu}
                                                        toggle={toggleOther}
                                                    >
                                                        <DropdownToggle className="btn nav-btn" tag="i">
                                                            <i className="bx bx-dots-horizontal-rounded" />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem href="#">Action</DropdownItem>
                                                            <DropdownItem href="#">
                                                                Another Action
                                                            </DropdownItem>
                                                            <DropdownItem href="#">
                                                                Something else
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>

                                <PerfectScrollbar
                                    className="chat-conversation p-3"
                                    containerRef={ref => setMessageBox(ref)}
                                >
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <div className="chat-day-title">
                                                <span className="title">Today</span>
                                            </div>
                                        </li>
                                        {messages &&
                                            map(messages, message => (
                                                <li
                                                    key={"test_k" + message.id}
                                                    className={
                                                        message.sender === currentUser.name
                                                            ? "right"
                                                            : ""
                                                    }
                                                >
                                                    <div className="conversation-list">
                                                        <div className="d-flex">

                                                            {/* <img src={avatar3} className="rounded-circle avatar-sm" alt=""></img> */}
                                                            <div className="flex-1">
                                                                <div className="ctext-wrap">
                                                                    <div className="ctext-wrap-content">
                                                                        <div className="conversation-name">
                                                                            <span className="time">{moment(message.createdAt).format(
                                                                                "hh:mm"
                                                                            )}</span>
                                                                            <p className="mb-0"> {message.message}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* <p>{message.message}</p> */}
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            href="#"
                                                                            className="btn nav-btn"
                                                                            tag="i"
                                                                        >
                                                                            <i className="bx bx-dots-vertical-rounded" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem href="#">
                                                                                Copy
                                                                            </DropdownItem>
                                                                            <DropdownItem href="#">
                                                                                Save
                                                                            </DropdownItem>
                                                                            <DropdownItem href="#">
                                                                                Forward
                                                                            </DropdownItem>
                                                                            <DropdownItem href="#">
                                                                                Delete
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </PerfectScrollbar>
                                <div className="p-3 border-top">
                                    <Row>
                                        <Col>
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    value={curMessage}
                                                    onKeyPress={onKeyPress}
                                                    onChange={e => setcurMessage(e.target.value)}
                                                    className="form-control border bg-soft-light"
                                                    placeholder="Enter Message..."
                                                />
                                            </div>
                                        </Col>

                                        <Col className="col-auto">
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={() =>
                                                    addMessage(currentRoomId, currentUser.name)
                                                }
                                                className="btn btn-primary chat-send w-md"
                                            >
                                                <span className="d-none d-sm-inline-block me-2">
                                                    Send
                                                </span>{" "}
                                                <i className="mdi mdi-send float-end"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </div>
                    </div>

                </Container>
            </div>
        </React.Fragment>
    );
};
Chat.propTypes = {
    chats: PropTypes.array,
    groups: PropTypes.array,
    messages: PropTypes.array,
    onGetChats: PropTypes.func,
    onGetGroups: PropTypes.func,
    onGetMessages: PropTypes.func,
    onAddMessage: PropTypes.func,
    onGetContacts: PropTypes.func,
};

const mapStateToProps = ({ Chat }) => ({
    chats: Chat.chats,
    groups: Chat.groups,
    messages: Chat.messages,
    contacts: Chat.contacts,
});

const mapDispatchToProps = dispatch => ({
    onGetChats: () => dispatch(getChats()),
    onGetGroups: () => dispatch(getGroups()),
    onGetMessages: roomId => dispatch(getMessages(roomId)),
    onAddMessage: roomId => dispatch(addMessage(roomId)),
    onGetContacts: () => dispatch(getContacts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);