import React from 'react';
import ReactApexChart from "react-apexcharts";

const RadialBarCharts = () => {
    const series = [76];
    var options = {
        chart: {
            type: 'radialBar',
            height: 162,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#f3f2f9",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: false,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                hollow: {
                    margin: 15,
                    size: "65%"
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },
        stroke: {
            lineCap: "round",
        },
        grid: {
            padding: {
                top: -10
            }
        },
        colors: ["#3b76e1"],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91]
            },
        },
        labels: ['Average Results'],
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="radialBar"
                height="162"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default RadialBarCharts;