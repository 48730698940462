import React, { useEffect } from 'react';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";

import Img1 from "../../assets/images/companies/img-1.png";
import Img2 from "../../assets/images/companies/img-2.png";
import Img3 from "../../assets/images/companies/img-3.png";
import Img4 from "../../assets/images/companies/img-4.png";
import Img5 from "../../assets/images/companies/img-5.png";
import Img6 from "../../assets/images/companies/img-6.png";
import { Link } from 'react-router-dom';



const ProjectList = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Project List",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>Project List | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <div className="">
                                <div className="table-responsive">
                                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: "100px" }} className="ps-4">#</th>
                                                <th scope="col">Projects</th>
                                                <th scope="col">Due Review</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Team</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="ps-4"><img src={Img1} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">New admin Design</Link></h5>
                                                    <p className="text-muted mb-0">It will be as simple as Occidental</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 236
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 202
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "87%" }} aria-valuenow="87" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 87%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <div className="avatar-sm">
                                                                    <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                                                                        A
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>


                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-4"><img src={Img2} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">Brand logo design</Link></h5>
                                                    <p className="text-muted mb-0">To achieve it would be necessary</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 45
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 95
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: "86%" }} aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 86%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar1} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar3} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-4"><img src={Img3} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">New Landing Design</Link></h5>
                                                    <p className="text-muted mb-0">For science, music, sport, etc</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 124
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 325
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 75%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar3} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar8} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar6} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-4"><img src={Img4} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">Redesign - Landing page</Link></h5>
                                                    <p className="text-muted mb-0">If several languages coalesce</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 225
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 326
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: "92%" }} aria-valuenow="92" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 92%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <div className="avatar-sm">
                                                                    <span className="avatar-title rounded-circle bg-warning text-white font-size-16">
                                                                        R
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-4"><img src={Img5} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">Borex Dashboard UI</Link></h5>
                                                    <p className="text-muted mb-0">Separate existence is a myth</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 321
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 214
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 75%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar1} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-4"><img src={Img6} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">Blog Template UI</Link></h5>
                                                    <p className="text-muted mb-0">For science, music, sport, etc</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 125
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 143
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: "88%" }} aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 88%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <div className="avatar-sm">
                                                                    <span className="avatar-title rounded-circle bg-danger text-white font-size-16">
                                                                        A
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="ps-4"><img src={Img3} alt="" className="avatar-sm" /></td>
                                                <td>
                                                    <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">Multipurpose Landing</Link></h5>
                                                    <p className="text-muted mb-0">It will be as simple as Occidental</p>
                                                </td>
                                                <td>
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-paperclip me-1 font-size-16 align-middle"></i> 241
                                                        </li>
                                                        <li className="list-inline-item me-3">
                                                            <i className="bx bx-chat me-1 font-size-16 align-middle"></i> 236
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="pe-5">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="progress" style={{ height: "6px" }}>
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "62%" }} aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <h6 className="mb-0 font-size-13"> 62%</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-group">
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar4} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar5} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                        <div className="avatar-group-item">
                                                            <Link to="#" className="d-inline-block">
                                                                <img src={avatar2} alt="" className="rounded-circle avatar-sm" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown className="dropdown">
                                                        <DropdownToggle tag="a" className="card-drop">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Action</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i> Load more </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectList;

