import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import bgImage1 from "../../assets/images/auth-bg.jpg";
import bgImage2 from "../../assets/images/auth-bg-1.jpg";
import bgImage3 from "../../assets/images/auth-bg-2.jpg";

import Img1 from "../../assets/images/product/img-1.png";
import Img2 from "../../assets/images/product/img-2.png";
import Img3 from "../../assets/images/product/img-3.png";

import Imgj1 from "../../assets/images/product/img-1.jpg";
import Imgj2 from "../../assets/images/product/img-2.jpg";
import Imgj3 from "../../assets/images/product/img-3.jpg";
import Img4 from "../../assets/images/product/img-4.jpg";
import Img5 from "../../assets/images/product/img-5.jpg";
import Img6 from "../../assets/images/product/img-6.jpg";

import classnames from "classnames";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"


// import Swiper core and required modules
import SwiperCore, {
    Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);


const Products = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Products",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Products | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="p-0">
                                    <div className="swiper-container slider rounded">
                                        <Swiper navigation={true} className="mySwiper swiper-wrapper" dir="ltr" autoplay={true} loop={true}>
                                            <SwiperSlide>
                                                <div className="swiper-slide p-4 rounded overflow-hidden ecommerce-slied-bg" style={{ backgroundImage: `url(${bgImage1})` }}>
                                                    <div className="bg-overlay bg-dark"></div>
                                                    <Row className="justify-content-center">
                                                        <Col xl={7} lg={11}>
                                                            <Row className="align-items-center">
                                                                <Col md={7} >
                                                                    <h3 className="mb-2 text-truncate text-white"><Link to="/ecommerce-product-detail" className="text-white">New Iphone 12 Max Pro +556GB </Link></h3>
                                                                    <ul className="list-unstyled px-0 mb-0 mt-4">
                                                                        <li><p className="text-white-50 mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Ceramic Shield front, matt glass back and stainless steel design</p></li>
                                                                        <li><p className="text-white-50 mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Water resistant to a depth of 6 metres for up to 30 minutes (IP68)</p></li>
                                                                        <li><p className="text-white-50 mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Pro 12MP camera system (Telephoto, Wide and Ultra Wide)</p></li>
                                                                    </ul>
                                                                    <h2 className="mb-0 mt-4 text-white"><span className="font-size-20">Form</span><b> $3,520</b> <span className="text-white-50 me-2"><del className="font-size-20 fw-normal">$5000</del></span></h2>
                                                                    <div className="mt-4">
                                                                        <button type="button" className="btn btn-success w-lg waves-effect waves-light">Buy Now</button>
                                                                    </div>
                                                                </Col>
                                                                <Col md={5} >
                                                                    <div className="p-4">
                                                                        <img src={Img1} className="img-fluid" alt="" />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide p-4 rounded overflow-hidden ecommerce-slied-bg" style={{ backgroundImage: `url(${bgImage2})` }}>
                                                    <div className="bg-overlay bg-dark"></div>
                                                    <Row className="justify-content-center">
                                                        <Col xl={7} lg={11}>
                                                            <Row className="align-items-center">
                                                                <Col md={5} >
                                                                    <div className="p-4">
                                                                        <img src={Img2} className="img-fluid" alt="" />
                                                                    </div>
                                                                </Col>

                                                                <Col md={6} className="offset-md-1">
                                                                    <h3 className="mb-2 text-truncate text-white"><Link to="/ecommerce-product-detail" className="text-white">New Iphone 11 Pro +128GB </Link></h3>
                                                                    <h5 className="text-white-50 font-size-16 mt-1">Heavy On Features, Light on Price.</h5>
                                                                    <ul className="list-unstyled px-0 mb-0 mt-4">
                                                                        <li><p className="text-white-50 mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                        <li><p className="text-white-50 mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> 13.7 cm (5.4-inch) Super Retina HDR and True Tone</p></li>
                                                                    </ul>
                                                                    <h2 className="mb-0 mt-4 text-white"><span className="font-size-20">Form</span><b> $2,360</b> <span className="text-white-50 me-2"><del className="font-size-20 fw-normal">$3500</del></span></h2>
                                                                    <div className="mt-4">
                                                                        <button type="button" className="btn btn-success w-lg waves-effect waves-light">Buy Now</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide p-4 rounded overflow-hidden ecommerce-slied-bg" style={{ backgroundImage: `url(${bgImage3})` }}>
                                                    <div className="bg-overlay bg-dark"></div>
                                                    <Row className="justify-content-center">

                                                        <Col xl={7} lg={11}>
                                                            <Row className="align-items-center">
                                                                <Col md={7} >
                                                                    <h3 className="mb-2 text-truncate text-white"><Link to="/ecommerce-product-detail" className="text-white">New Iphone 13 Max Pro +256GB </Link></h3>
                                                                    <h5 className="text-white font-size-16 mt-1">Heavy On Features, Light on Price.</h5>
                                                                    <ul className="list-unstyled px-0 mb-0 mt-4">
                                                                        <li><p className="text-white-50 mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Ceramic Shield front, matt glass back and stainless steel design</p></li>
                                                                        <li><p className="text-white-50 mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Water resistant to a depth of 6 metres for up to 30 minutes (IP68)</p></li>
                                                                        <li><p className="text-white-50 mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-white me-1"></i> Pro 12MP camera system (Telephoto, Wide and Ultra Wide)</p></li>
                                                                    </ul>
                                                                    <h2 className="mb-0 mt-4 text-white"><span className="font-size-20">Form</span><b> $7,999</b> <span className="text-white-50 me-2"><del className="font-size-20 fw-normal">$9,999</del></span></h2>
                                                                    <div className="mt-4">
                                                                        <button type="button" className="btn btn-success w-lg waves-effect waves-light">Buy Now</button>
                                                                    </div>
                                                                </Col>
                                                                <Col md={5} >
                                                                    <div className="p-4">
                                                                        <img src={Img3} className="img-fluid" alt="" />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Top Product</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col xl={4} sm={6}>
                                            <h6 className="text-uppercase">Price</h6>
                                            <div id="priceslider"></div>
                                        </Col>
                                        <Col xl={8} sm={6}>

                                            <form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                                                <div className="search-box me-2">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control border-0 bg-light" placeholder="Search..." />
                                                        <i className="search-icon" data-eva="search-outline" data-eva-height="26" data-eva-width="26"></i>
                                                    </div>
                                                </div>
                                                <Nav pills className="nav product-view-nav justify-content-end mt-3 mt-sm-0">
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: customActiveTab === "1",

                                                            })}
                                                            onClick={() => {
                                                                toggleCustom("1");
                                                            }}
                                                        >
                                                            <i className="bx bx-grid-alt"></i>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: customActiveTab === "2",
                                                            })}
                                                            onClick={() => {
                                                                toggleCustom("2");
                                                            }}
                                                        >
                                                            <i className="bx bx-list-ul"></i>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </form>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col xl={12} >
                                            <TabContent
                                                activeTab={customActiveTab}
                                            >
                                                <TabPane tabId="1" id="product">
                                                    <Row>
                                                        <Col xl={6} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <Col md={5} >
                                                                            <img src={Imgj1} className="img-fluid rounded" alt="" />
                                                                        </Col>
                                                                        <Col md={7}>
                                                                            <div className="mt-3 mt-lg-0">
                                                                                <UncontrolledDropdown className="dropdown float-end" direction='start'>
                                                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem>Edit</DropdownItem>
                                                                                        <DropdownItem>Action</DropdownItem>
                                                                                        <DropdownItem>Remove</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>


                                                                                <h4 className="mb-2 font-size-20 text-truncate"><Link to="/ecommerce-product-detail" className="text-dark">Waterproof Mobile Phone </Link></h4>
                                                                                <p className="text-muted mb-0">
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star-half text-warning"></i>
                                                                                </p>

                                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 12MP TrueDepth front camera </p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Face ID for secure authentication</p></li>
                                                                                </ul>

                                                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$500</del></span><b>$450</b></h3>

                                                                                <div className="mt-4">
                                                                                    <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <Col md={5} >
                                                                            <img src={Imgj2} className="img-fluid rounded" alt="" />
                                                                        </Col>
                                                                        <Col md={7} >
                                                                            <div className="mt-3 mt-lg-0">
                                                                                <UncontrolledDropdown className="dropdown float-end" direction='start'>
                                                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem>Edit</DropdownItem>
                                                                                        <DropdownItem>Action</DropdownItem>
                                                                                        <DropdownItem>Remove</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                                <h4 className="mb-2 font-size-20 text-truncate"><Link to="/ecommerce-product-detail" className="text-dark">Smartphone Dual Camera </Link></h4>
                                                                                <p className="text-muted mb-0">
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                </p>

                                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 32MP TrueDepth front camera </p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Fast-charge capable</p></li>
                                                                                </ul>

                                                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$280</del></span><b>$240</b></h3>

                                                                                <div className="mt-4">
                                                                                    <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <Col md={5} >
                                                                            <img src={Imgj3} className="img-fluid rounded" alt="" />
                                                                        </Col>
                                                                        <Col md={7} >
                                                                            <div className="mt-3 mt-lg-0">
                                                                                <UncontrolledDropdown className="dropdown float-end" direction='start'>
                                                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem>Edit</DropdownItem>
                                                                                        <DropdownItem>Action</DropdownItem>
                                                                                        <DropdownItem>Remove</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                                <h4 className="mb-2 font-size-20 text-truncate"><Link to="/ecommerce-product-detail" className="text-dark">Black Colour Smartphone</Link></h4>
                                                                                <p className="text-muted mb-0">
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                </p>

                                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE </p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> LiDAR Scanner for Night mode</p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Face ID for secure authentication</p></li>
                                                                                </ul>

                                                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$750</del></span><b>$950</b></h3>

                                                                                <div className="mt-4">
                                                                                    <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <Col md={5} >
                                                                            <img src={Img4} className="img-fluid rounded" alt="" />
                                                                        </Col>
                                                                        <Col md={7} >
                                                                            <div className="mt-3 mt-lg-0">
                                                                                <UncontrolledDropdown className="dropdown float-end" direction='start'>
                                                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem>Edit</DropdownItem>
                                                                                        <DropdownItem>Action</DropdownItem>
                                                                                        <DropdownItem>Remove</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                                <h4 className="mb-2 font-size-20 text-truncate"><Link to="/ecommerce-product-detail" className="text-dark">Latest Smartphone Under 20000</Link></h4>
                                                                                <p className="text-muted mb-0">
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star-half text-warning"></i>
                                                                                </p>

                                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 20MP TrueDepth front camera </p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Fast-charge capable</p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Compatible with MagSafe accessories</p></li>
                                                                                </ul>

                                                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$420</del></span><b>$380</b></h3>

                                                                                <div className="mt-4">
                                                                                    <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <Col md={5} >
                                                                            <img src={Img5} className="img-fluid rounded" alt="" />
                                                                        </Col>
                                                                        <Col md={7} >
                                                                            <div className="mt-3 mt-lg-0">
                                                                                <UncontrolledDropdown className="dropdown float-end" direction='start'>
                                                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem>Edit</DropdownItem>
                                                                                        <DropdownItem>Action</DropdownItem>
                                                                                        <DropdownItem>Remove</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                                <h4 className="mb-2 font-size-20 text-truncate"><Link to="/ecommerce-product-detail" className="text-dark">New Phone Max Pro </Link></h4>
                                                                                <p className="text-muted mb-0">
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                </p>

                                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> MagSafe and Qi wireless charging </p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> A15 Bionic chip with new 6-core CPU </p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Face ID for secure authentication</p></li>
                                                                                </ul>

                                                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$470</del></span><b>$390</b></h3>

                                                                                <div className="mt-4">
                                                                                    <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <Col md={5} >
                                                                            <img src={Img6} className="img-fluid rounded" alt="" />
                                                                        </Col>
                                                                        <Col md={7} >
                                                                            <div className="mt-3 mt-lg-0">
                                                                                <UncontrolledDropdown className="dropdown float-end" direction='start'>
                                                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem>Edit</DropdownItem>
                                                                                        <DropdownItem>Action</DropdownItem>
                                                                                        <DropdownItem>Remove</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                                <h4 className="mb-2 font-size-20 text-truncate"><Link to="/ecommerce-product-detail" className="text-dark">Smart Phone Pro +256</Link></h4>
                                                                                <p className="text-muted mb-0">
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                    <i className="bx bxs-star-half text-warning"></i>
                                                                                </p>

                                                                                <ul className="list-unstyled ps-0 mb-0 mt-3">
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 13.7 cm (5.4-inch) Super Retina HDR and True Tone</p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                                    <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                    <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Compatible with MagSafe accessories</p></li>
                                                                                </ul>

                                                                                <h3 className="mb-0 mt-4"><span className="text-muted me-2"><del className="font-size-22 fw-normal">$490</del></span><b>$320</b></h3>

                                                                                <div className="mt-4">
                                                                                    <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane tabId="2" id="product-list">
                                                    <Row>
                                                        <Col xl={12} >
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-center">
                                                                        <div className="table-responsive px-3">
                                                                            <table className="table table-striped align-middle table-nowrap">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="avatar-lg me-4">
                                                                                                <img src={Imgj1} className="img-fluid rounded" alt="" />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                <h5 className="font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Waterproof Mobile Phone</Link></h5>
                                                                                                <p className="text-muted mb-0 mt-2 pt-2">
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star-half text-warning"></i>
                                                                                                </p>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <ul className="list-unstyled ps-0 mb-0">
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 12MP TrueDepth front camera </p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                                                <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "180px" }}>
                                                                                            <p>Colors :</p>
                                                                                            <ul className="list-inline mb-0 text-muted product-color">
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-dark"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-success"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-primary"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "220px" }}>
                                                                                            <h3 className="mb-0 font-size-20"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$500</del></span><b>$450</b></h3>
                                                                                        </td>

                                                                                        <td>
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                        </td>

                                                                                        <td>
                                                                                            <UncontrolledDropdown className="dropdown float-end">
                                                                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-20">
                                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>Edit</DropdownItem>
                                                                                                    <DropdownItem>Action</DropdownItem>
                                                                                                    <DropdownItem>Remove</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="avatar-lg me-4">
                                                                                                <img src={Imgj2} className="img-fluid rounded" alt="" />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                <h5 className="font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Smartphone Dual Camera</Link></h5>
                                                                                                <p className="text-muted mb-0 mt-2 pt-2">
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                </p>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <ul className="list-unstyled ps-0 mb-0">
                                                                                                <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE</p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 32MP TrueDepth front camera</p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "180px" }}>
                                                                                            <p>Colors :</p>
                                                                                            <ul className="list-inline mb-0 text-muted product-color">
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-primary"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-danger"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-warning"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "220px" }}>
                                                                                            <h3 className="mb-0 font-size-20"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$280</del></span><b>$240</b></h3>
                                                                                        </td>

                                                                                        <td>
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                        </td>

                                                                                        <td>
                                                                                            <UncontrolledDropdown className="dropdown float-end">
                                                                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-20">
                                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>Edit</DropdownItem>
                                                                                                    <DropdownItem>Action</DropdownItem>
                                                                                                    <DropdownItem>Remove</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="avatar-lg me-4">
                                                                                                <img src={Imgj3} className="img-fluid rounded" alt="" />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                <h5 className="font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Black Colour Smartphone</Link></h5>
                                                                                                <p className="text-muted mb-0 mt-2 pt-2">
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                </p>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <ul className="list-unstyled ps-0 mb-0">
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 5G and Gigabit LTE </p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> LiDAR Scanner for Night mode</p></li>
                                                                                                <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Face ID for secure authentication</p></li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "180px" }}>
                                                                                            <p>Colors :</p>
                                                                                            <ul className="list-inline mb-0 text-muted product-color">
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-info"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-danger"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-dark"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "220px" }}>
                                                                                            <h3 className="mb-0 font-size-20"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$500</del></span><b>$450</b></h3>
                                                                                        </td>

                                                                                        <td>
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                        </td>

                                                                                        <td>
                                                                                            <UncontrolledDropdown className="dropdown float-end">
                                                                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-20">
                                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>Edit</DropdownItem>
                                                                                                    <DropdownItem>Action</DropdownItem>
                                                                                                    <DropdownItem>Remove</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="avatar-lg me-4">
                                                                                                <img src={Img4} className="img-fluid rounded" alt="" />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                <h5 className="font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Latest Smartphone Under 2000</Link></h5>
                                                                                                <p className="text-muted mb-0 mt-2 pt-2">
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star-half text-warning"></i>
                                                                                                </p>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <ul className="list-unstyled ps-0 mb-0">
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 20MP TrueDepth front camera </p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> LiDAR Scanner for Night mode</p></li>
                                                                                                <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Compatible with MagSafe accessories</p></li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "180px" }}>
                                                                                            <p>Colors :</p>
                                                                                            <ul className="list-inline mb-0 text-muted product-color">
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-primary"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-success"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-warning"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "220px" }}>
                                                                                            <h3 className="mb-0 font-size-20"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$420</del></span><b>$380</b></h3>
                                                                                        </td>

                                                                                        <td>
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                        </td>

                                                                                        <td>
                                                                                            <UncontrolledDropdown className="dropdown float-end">
                                                                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-20">
                                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>Edit</DropdownItem>
                                                                                                    <DropdownItem>Action</DropdownItem>
                                                                                                    <DropdownItem>Remove</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="avatar-lg me-4">
                                                                                                <img src={Img5} className="img-fluid rounded" alt="" />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                <h5 className="font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">New Phone Max Pro</Link></h5>
                                                                                                <p className="text-muted mb-0 mt-2 pt-2">
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                </p>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <ul className="list-unstyled ps-0 mb-0">
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 2MagSafe and Qi wireless charging </p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> A15 Bionic chip with new 6-core CPU</p></li>
                                                                                                <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Compatible with MagSafe accessories</p></li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "180px" }}>
                                                                                            <p>Colors :</p>
                                                                                            <ul className="list-inline mb-0 text-muted product-color">
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-dark"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-danger"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-primary"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "220px" }}>
                                                                                            <h3 className="mb-0 font-size-20"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$470</del></span><b>$390</b></h3>
                                                                                        </td>

                                                                                        <td>
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                        </td>

                                                                                        <td>
                                                                                            <UncontrolledDropdown className="dropdown float-end">
                                                                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-20">
                                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>Edit</DropdownItem>
                                                                                                    <DropdownItem>Action</DropdownItem>
                                                                                                    <DropdownItem>Remove</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="avatar-lg me-4">
                                                                                                <img src={Img6} className="img-fluid rounded" alt="" />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div>
                                                                                                <h5 className="font-size-18"><Link to="/ecommerce-product-detail" className="text-dark">Smart Phone Pro +256</Link></h5>
                                                                                                <p className="text-muted mb-0 mt-2 pt-2">
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star text-warning"></i>
                                                                                                    <i className="bx bxs-star-half text-warning"></i>
                                                                                                </p>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <ul className="list-unstyled ps-0 mb-0">
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> 13.7 cm (5.4-inch) Super Retina HDR and True Tone</p></li>
                                                                                                <li><p className="text-muted mb-1 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Battery life: Up to 22 hours of video playback</p></li>
                                                                                                <li><p className="text-muted mb-0 text-truncate"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i> Compatible with MagSafe accessories</p></li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "180px" }}>
                                                                                            <p>Colors :</p>
                                                                                            <ul className="list-inline mb-0 text-muted product-color">
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-primary"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-warning"></i>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <i className="mdi mdi-circle font-size-16 text-info"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>

                                                                                        <td style={{ width: "220px" }}>
                                                                                            <h3 className="mb-0 font-size-20"><span className="text-muted me-2"><del className="font-size-16 fw-normal">$470</del></span><b>$320</b></h3>
                                                                                        </td>

                                                                                        <td>
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light"><i className="bx bx-cart me-2 font-size-15 align-middle"></i> Add To Cart</button>
                                                                                        </td>

                                                                                        <td>
                                                                                            <UncontrolledDropdown className="dropdown float-end">
                                                                                                <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-20">
                                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>Edit</DropdownItem>
                                                                                                    <DropdownItem>Action</DropdownItem>
                                                                                                    <DropdownItem>Remove</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                            </TabContent>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <ul className="pagination float-end pagination-rounded mb-2">
                                                <li className="page-item disabled">
                                                    <Link to="#" className="page-link"><i className="mdi mdi-chevron-left"></i></Link>
                                                </li>
                                                <li className="page-item active">
                                                    <Link to="#" className="page-link">1</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#" className="page-link">2</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#" className="page-link">3</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#" className="page-link">4</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#" className="page-link">5</Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link to="#" className="page-link"><i className="mdi mdi-chevron-right"></i></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Products;