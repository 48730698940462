import React, { useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import ChartArea from './ChartArea';

//import Components
import OrderState from './OrderState';
import TopProduct from './TopProduct';

const UserActivity = () => {
    //user Activity DropDown
    const [sortBy, setSortBy] = useState(false);
    const toggleSortBy = () => {
        setSortBy(!sortBy);
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={4}>
                    <Card>
                        <CardBody className="pb-1">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <h5 className="card-title mb-3">User Activity</h5>
                                </div>
                                <div className="flex-shrink-0">
                                    <Dropdown
                                        isOpen={sortBy}
                                        toggle={toggleSortBy}
                                    >
                                        <DropdownToggle
                                            type="button"
                                            tag="a"
                                            className="text-muted"
                                        >
                                            Weekly<span><i className="mdi mdi-chevron-down ms-1"></i></span>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem to="#">Yearly</DropdownItem>
                                            <DropdownItem to="#">Monthly</DropdownItem>
                                            <DropdownItem to="#">Weekly</DropdownItem>
                                            <DropdownItem to="#">Today</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div>
                                <p className="text-muted mb-1">This Month</p>
                                <h4>16,543</h4>
                            </div>

                            <div className="m-n3">
                                <div id="chart-area"  style={{ minHeight : "285px"}}>
                                    <ChartArea />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl={4} md={6}>
                    <OrderState />
                </Col>

                <Col xl={4} md={6} >
                    <TopProduct />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserActivity;
