import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-eva-icons';
import {
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
} from "reactstrap";

import SimpleBar from 'simplebar-react';

//Import images
import logoDarkSm from "../../assets/images/logo-dark-sm.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSm from "../../assets/images/logo-light-sm.png";

//import language flag images
import usFlag from '../../assets/images/flags/us.jpg';
import spainFlag from '../../assets/images/flags/spain.jpg';
import germanyFlag from '../../assets/images/flags/germany.jpg';
import italyFlag from '../../assets/images/flags/italy.jpg';
import russiaFlag from '../../assets/images/flags/russia.jpg';

//import webApps images
import gitHub from '../../assets/images/brands/github.png';
import bitbucket from '../../assets/images/brands/bitbucket.png';
import dribbble from '../../assets/images/brands/dribbble.png';
import dropbox from '../../assets/images/brands/dropbox.png';
import mail_chimp from '../../assets/images/brands/mail_chimp.png';
import slack from '../../assets/images/brands/slack.png';

//user(avatar) Image
import avatarImage1 from '../../assets/images/users/avatar-1.jpg';
import avatarImage3 from '../../assets/images/users/avatar-3.jpg';
import avatarImage6 from '../../assets/images/users/avatar-6.jpg';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/Common/BreadCrumb';

import { connect } from "react-redux";

//redux
import { useDispatch } from "react-redux";

// Redux Store
import {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
} from "../../store/actions";

const Header = ({ breadCrumbItems, showRightSidebar }) => {
    const dispatch = useDispatch();
    //Seacrh dropdown
    const [search, setSearch] = useState(false);
    const toggleSearch = () => {
        setSearch(!search);
    };

    //Language Dropdown
    const [languages, setLanguage] = useState(false);
    const toggleLanguage = () => {
        setLanguage(!languages);
    };

    //Webapps Dropdown
    const [webapps, setWebapps] = useState(false);
    const toggleWebapps = () => {
        setWebapps(!webapps);
    };

    //Notification Dropdown
    const [notification, setNotification] = useState(false);
    const toggleNotification = () => {
        setNotification(!notification);
    };

    //Profile Dropdown
    const [profile, setProfile] = useState(false);
    const toggleProfile = () => {
        setProfile(!profile);
    };

    //scroll navbar
    const [navClass, setnavClass] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("sticky");
        } else {
            setnavClass("");
        }
    }

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("sidebar-enable");
            body.setAttribute("data-sidebar-size","lg");
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar" className={"isvertical-topbar " + navClass}>
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoDarkSm} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDarkSm} alt="" height="22" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-lg">
                                    <img src={logoLight} alt="" height="22" />
                                </span>
                                <span className="logo-sm">
                                    <img src={logoLightSm} alt="" height="22" />
                                </span>
                            </Link>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger" onClick={() => tToggle() }>
                            <div className="hamburger-icon open">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>

                        <BreadCrumb breadCrumbItems={breadCrumbItems} />
                    </div>
                    <div className="d-flex">
                        <Dropdown isOpen={search} toggle={toggleSearch}>
                            <DropdownToggle
                                type="button" tag="button" className="btn header-item">
                                <Icon name="search-outline" fill="#555b6d" size="large" />
                            </DropdownToggle>
                            <div
                                className={
                                    search
                                        ? "dropdown-menu dropdown-menu-end dropdown-menu-md p-0 show"
                                        : "dropdown-menu dropdown-menu-end dropdown-menu-md p-0"
                                }>
                                <form className="p-2">
                                    <div className="search-box">
                                        <div className="position-relative">
                                            <Input type="text" className="form-control bg-light border-0" placeholder="Search..." />
                                            <i className="search-icon" data-eva="search-outline" data-eva-height="26" data-eva-width="26"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Dropdown>

                        <Dropdown className="d-inline-block language-switch" isOpen={languages} toggle={toggleLanguage}>
                            <DropdownToggle tag="button" type="button" className="btn header-item">
                                <img className="header-lang-img" src={usFlag} alt="Header Language" height="16" />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">

                                <Link to="#" className="dropdown-item notify-item language" data-lang="eng">
                                    <img src={usFlag} alt="" className="me-1" height="12" /> <span className="align-middle">English</span>
                                </Link>

                                <Link to="#" className="dropdown-item notify-item language" data-lang="sp">
                                    <img src={spainFlag} alt="" className="me-1" height="12" /> <span className="align-middle">Spanish</span>
                                </Link>

                                <Link to="#" className="dropdown-item notify-item language" data-lang="gr">
                                    <img src={germanyFlag} alt="" className="me-1" height="12" /> <span className="align-middle">German</span>
                                </Link>

                                <Link to="#" className="dropdown-item notify-item language" data-lang="it">
                                    <img src={italyFlag} alt="" className="me-1" height="12" /> <span className="align-middle">Italian</span>
                                </Link>

                                <Link to="#" className="dropdown-item notify-item language" data-lang="ru">
                                    <img src={russiaFlag} alt="" className="me-1" height="12" /> <span className="align-middle">Russian</span>
                                </Link>
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown className="d-none d-lg-inline-block" isOpen={webapps} toggle={toggleWebapps}>
                            <DropdownToggle
                                tag="button"
                                type="button" className="btn header-item noti-icon">
                                <i className="icon-sm" data-eva="grid-outline"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                <div className="p-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h5 className="m-0 font-size-15"> Web Apps </h5>
                                        </Col>
                                        <div className="col-auto">
                                            <Link to="#" className="small fw-semibold text-decoration-underline"> View All</Link>
                                        </div>
                                    </Row>
                                </div>
                                <div className="px-lg-2 pb-2">
                                    <Row className="g-0">
                                        <Col >
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={gitHub} alt="Github" />
                                                <span>GitHub</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={bitbucket} alt="bitbucket" />
                                                <span>Bitbucket</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={dribbble} alt="dribbble" />
                                                <span>Dribbble</span>
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row className="g-0">
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={dropbox} alt="dropbox" />
                                                <span>Dropbox</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={mail_chimp} alt="mail_chimp" />
                                                <span>Mail Chimp</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={slack} alt="slack" />
                                                <span>Slack</span>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown className="d-inline-block" isOpen={notification} toggle={toggleNotification}>
                            <DropdownToggle
                                tag="button"
                                type="button" className="btn header-item noti-icon" id="page-header-notifications-dropdown">
                                {/* <i className="icon-sm" data-eva="bell-outline"></i> */}
                                <Icon name="bell-outline" fill="#555b6d" size="large" />
                                <span className="noti-dot bg-danger rounded-pill">4</span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                <div className="p-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h5 className="m-0 font-size-15"> Notifications </h5>
                                        </Col>
                                        <div className="col-auto">
                                            <Link to="#" className="small fw-semibold text-decoration-underline"> Mark all as read</Link>
                                        </div>
                                    </Row>
                                </div>
                                <SimpleBar data-simplebar style={{ maxHeight: '250px' }}>
                                    <Link to="#" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <img src={avatarImage3} className="rounded-circle avatar-sm" alt="user-pic" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">James Lemire</h6>
                                                <div className="font-size-13 text-muted">
                                                    <p className="mb-1">It will seem like simplified English.</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span>1 hour ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="#" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 avatar-sm me-3">
                                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i className="bx bx-cart"></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Your order is placed</h6>
                                                <div className="font-size-13 text-muted">
                                                    <p className="mb-1">If several languages coalesce the grammar</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span>3 min ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="#" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 avatar-sm me-3">
                                                <span className="avatar-title bg-success rounded-circle font-size-16">
                                                    <i className="bx bx-badge-check"></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Your item is shipped</h6>
                                                <div className="font-size-13 text-muted">
                                                    <p className="mb-1">If several languages coalesce the grammar</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span>3 min ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="#" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <img src={avatarImage6} className="rounded-circle avatar-sm" alt="user-pic" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Salena Layfield</h6>
                                                <div className="font-size-13 text-muted">
                                                    <p className="mb-1">As a skeptical Cambridge friend of mine occidental.</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span>1 hour ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SimpleBar>
                                <div className="p-2 border-top d-grid">
                                    <Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to="#">
                                        <i className="uil-arrow-circle-right me-1"></i> <span>View More..</span>
                                    </Link>
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        <div className="dropdown d-inline-block">
                            <button
                                onClick={() => {
                                    dispatch(showRightSidebarAction(!showRightSidebar));
                                }}
                                type="button"
                                className="btn header-item noti-icon right-bar-toggle"
                                id="right-bar-toggle">
                                <Icon name="settings-outline" fill="#555b6d" size="large" />
                            </button>
                        </div>


                        <Dropdown className="d-inline-block" isOpen={profile} toggle={toggleProfile}>
                            <DropdownToggle tag="a" type="button"
                                className="btn header-item user text-start d-flex align-items-center"
                                id="page-header-user-dropdown"
                                to="#"
                            >
                                <img className="rounded-circle header-profile-user" src={avatarImage1}
                                    alt="Header Avatar" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end pt-0">
                                <div className="p-3 border-bottom">
                                    <h6 className="mb-0">Jennifer Bennett</h6>
                                    <p className="mb-0 font-size-11 text-muted">jennifer.bennett@email.com</p>
                                </div>
                                <DropdownItem href="/contacts-profile"><i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Profile</span></DropdownItem>
                                <DropdownItem href="/apps-chat"><i className="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Messages</span></DropdownItem>
                                <DropdownItem href="/pages-faqs"><i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Help</span></DropdownItem>
                                <div className="dropdown-divider"></div>
                                <DropdownItem href="#"><i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Balance : <b>$6951.02</b></span></DropdownItem>
                                <DropdownItem className="d-flex align-items-center" href="#"><i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Settings</span><span className="badge badge-soft-success ms-auto">New</span></DropdownItem>
                                <DropdownItem href="/auth-lock-screen"><i className="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></DropdownItem>
                                <DropdownItem href="/auth-logout"><i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Logout</span></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})((Header));
